import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-interval-selector',
  templateUrl: './interval-selector.component.html',
  styleUrls: ['./interval-selector.component.css'],
})
export class IntervalSelectorComponent implements AfterViewInit, OnChanges {

  @Input() defaultInterval: '1M' | '3M' | '6M' | 'MTD' | 'YTD' = '1M';
  @Input() date: number;

  @Output() triggerIntervalChanged: EventEmitter<{ startDate: number, endDate: number }> = new EventEmitter;

  public constructor() {

  }

  ngAfterViewInit(): void {
    this.setIntervalDates(this.defaultInterval);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.date?.firstChange && changes.date?.previousValue !== changes.date?.currentValue) {
      this.date = changes.date.currentValue;
    }
  }

  public onIntervalChanged(event: any) {
    this.setIntervalDates(event?.value);
  }

  private setIntervalDates(selectedInterval: string) {
    if (selectedInterval) {
      let startDate;

      if (!selectedInterval.includes('TD')) {
        startDate = moment(this.date).subtract(Number(selectedInterval.replace('M', '')), 'months').valueOf();
      } else {
        const startOf = selectedInterval === 'YTD' ? 'year' : 'month';
        startDate = moment().startOf(startOf).valueOf();
      }

      const interval = {
        startDate: startDate,
        endDate: this.date,
      };

      this.triggerIntervalChanged.emit(interval);
    }
  }

}
