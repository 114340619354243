import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { CogniteClient } from '@cognite/sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CogniteAuthService {

  public token:string;
  public loggedInUser:any;
  public loggedInUserId:any;

  pca : PublicClientApplication;
  homeAccountId : any;

  constructor(private readonly msalService: MsalService) {
    this.pca = this.msalService.instance as PublicClientApplication;
  }

  getapiToken = async () => {
    const account = this.pca.getAllAccounts()[0];

    try {
      const token = await this.pca.acquireTokenSilent({ account, scopes:environment.Scopes });

      if (token) {
        console.log(`Recieved token for cognite client is : ${token.accessToken}`);
        return token.accessToken;
      } else {
        throw new Error('Empty token');
      }
    } catch (e) {
      console.error('Failed to acquire token for congiteclient: ', e);
      return '';
    }
  };

  getToken = async () => {
    const account = this.pca.getAllAccounts()[0];

    try {
      const token = await this.pca.acquireTokenSilent({ account, scopes: [`${environment.baseUrl}DATA.VIEW`] });

      if (token) {
        console.log('Recieved token for cognite client is: ', token.accessToken);
        return token.accessToken;
      } else {
        throw new Error('Empty token');
      }
    } catch (e) {
      console.error('Failed to acquire token for congiteclient: ', e);
      return '';
    }
  };

  getaccessToken = async () => {
    const scopes = [ `${environment.baseUrl}DATA.VIEW` ];
    const account = this.pca.getAllAccounts()[0];

    if (!account)
      throw new Error('No user found');

    this.loggedInUser = account.name;
    this.homeAccountId = account.homeAccountId;
    this.loggedInUserId = account.username;

    const token = await this.pca.acquireTokenSilent({ account, scopes });

    return token.accessToken;
  };

   sdk: CogniteClient = new CogniteClient({
    appId: environment.clientId,
    project:environment.project,
    baseUrl:environment.baseUrl,
    getToken:  this.getaccessToken,
  });

  getSdk() {
    return this.sdk;
  }
}
