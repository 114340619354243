import { GridComponent } from "@progress/kendo-angular-grid";
import moment from 'moment';

export function addSortableDates(data: any[], grid: GridComponent | null = null, sortColumn: string | null = null, dateFormat: string = 'DD-MM-YYYY HH:mm', sortDirection: string = 'desc', convertFormat: string = 'DD-MM-YYYY HH:mm'): any[] {
  if (data?.length > 0) {
    const sortableColumn = convertDates(data, dateFormat, convertFormat);

    if (grid && sortableColumn)
      data = sortDates(grid, data, sortColumn ?? sortableColumn, sortDirection);
  }

  return data;
}

function convertDates(data: any[], dateFormat: string, convertFormat: string): string | null {
  const dateColumns = Object.keys(data[0]).filter(e => e.toLowerCase().includes('date'));
  let sortableColumn: string | null = null;

  for (const r of data) {
    for (const c of dateColumns) {
      const colName = `${c}_ts`;

      try {
        r[colName] = moment(r[c], dateFormat).valueOf();
        if (  !r[c] &&  r[c] === '-' ){
        r[c] = moment(r[c], dateFormat).format(convertFormat);
        }

        if (!sortableColumn)
          sortableColumn = colName;
      } catch (e) {
        r[colName] = r[c];
        console.error(`Failed to convert value: ${r[c]} to date.`, e);
      }
    }
  }

  return sortableColumn;
}

function sortDates(grid: GridComponent, data: any[], sortableColumn: string, sortDirection: string) {
  grid.sort = [{ field: sortableColumn, dir: (sortDirection as any) }];

  if (sortDirection === 'desc') {
    data.sort((a: any, b: any) => a[sortableColumn] >= b[sortableColumn] ? -1 : 1);
  } else {
    data.sort((a: any, b: any) => a[sortableColumn] <= b[sortableColumn] ? -1 : 1);
  }

  return data;
}
