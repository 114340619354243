<!-- <div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.Field">Field Level</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.Well">Well Level</mat-button-toggle>
  </mat-button-toggle-group>
</div> -->
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [getListofAllWellsUnderField]="true" [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" (onWellchange)="onFilterWellChange($event)" (onclear)="clear()" (ondatechange)="onDateRangeChange($event)" [showonlyFieldControl]="selectedtab === tabOptions.Field"></app-hierarchy>
</div>
<div class="page-content">
  <div *ngIf="selectedtab === tabOptions.Field">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Pump Curve variables</mat-card-title>
        <div class="header-right-tools">
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="onAddrecordClick()">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add</span>
          </button>
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base delete-button" type="button" (click)="ondelete(undefined)" [disabled]="selectedrows === undefined || selectedrows?.length === 0">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Delete</span>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="PumpCurvVariablesData" [resizable]="true" [pageable]="true" [filterable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="sequence" [(selectedKeys)]="selectedrows" [sortable]="true" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="PumpCurvVariablesData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-checkbox-column [showSelectAll]="true" [width]="50"></kendo-grid-checkbox-column>
          <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="sequence" title="sequence" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="field" title="Field" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column field="startdate_ts" title="Start Date Time" [width]="150" [filterable]="true">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="startdate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="gasgravity" title="Gas Gravity (SG)" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column field="apiofoil" title="API Of Oil" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column field="formation" title="Formation" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column field="pw" title="Formation Water Density (pW) (kg/m3)" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column title="Action" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItem)">
                <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
              </a>
              <a slb-icon-button routerLink="." title="Delete" (click)="ondelete(dataItem)">
                <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Pump Curve Variables.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Pump Curve Variables.pdf" [allPages]="true">
            <kendo-grid-column field="field" title="Field"></kendo-grid-column>
            <kendo-grid-column field="startdate" title="Start Date Time" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="gasgravity" title="Gas Gravity (SG)"></kendo-grid-column>
            <kendo-grid-column field="apiofoil" title="API Of Oil"></kendo-grid-column>
            <kendo-grid-column field="formation" title="Formation"></kendo-grid-column>
            <kendo-grid-column field="pw" title="Formation Water Density (pW) (kg/m3)"></kendo-grid-column>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
    <ng-template #addRecordDialog>
      <mat-dialog-content style="height: auto; width: 500px;" class="update-range-dialog-content">
        <mat-card-title class="headerLabel">{{ formMode === 'Add' ? 'Add New' : 'Update'}} Record</mat-card-title>
        <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
        <div class="form-container" style="margin-top: 30px;">
          <form (ngSubmit)='save(formgroup)' [formGroup]='formgroup'>
            <slb-date-picker hint="" [(value)]="startdate" label="Start Date Time" [maxValue]="todaysdate" [disabled]="formMode === 'Edit'"></slb-date-picker>
            <br>
            <mat-form-field slbFormField class="form-field" appearance="outline" #field>
              <mat-label>Field</mat-label>
              <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field" required (selectionChange)="onformfieldChange($event)" [hideSingleSelectionIndicator]="true" [multiple]="true">
                <mat-option *ngFor="let field of fieldList" [value]="field.externalId">{{ field.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="space-div"></div>
            <mat-form-field slbFormField class="form-field" appearance="outline" #field>
              <mat-label>Gas Gravity</mat-label>
              <input type="number" matInput formControlName="gasgravity"><span>SG</span>
            </mat-form-field>
            <div class="space-div"></div>
            <mat-form-field slbFormField class="form-field" appearance="outline" #field>
              <mat-label>API of Oil</mat-label>
              <input type="number" matInput formControlName="apiofoil">
            </mat-form-field>
            <mat-form-field slbFormField class="form-field" appearance="outline" #field>
              <mat-label>Formation Water Density (pW)</mat-label>
              <input type="number" matInput formControlName="pw"><span>kg/m3</span>
            </mat-form-field>
            <div class="space-div"></div>
            <mat-form-field slbFormField class="form-field" appearance="outline" #field>
              <mat-label>Formation</mat-label>
              <mat-select #input [panelClass]="'dls-panel'" formControlName='formation' [hideSingleSelectionIndicator]="true" placeholder="Select">
                <mat-option value="1">Fategarh</mat-option>
                <mat-option value="2" *ngIf="formgroup.controls['field'].value.includes('Mangala')">Lower Fategarh</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" class="update-range-dialog-actions">
        <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
        <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="save(formgroup)" matDialogClose="yes">{{ formMode === 'Add' ? 'Create' : 'Update' }}</button>
      </mat-dialog-actions>
    </ng-template>
  </div>
  <div *ngIf="selectedtab === tabOptions.Well">
    <app-wellvariables #wellvariablesComponent [pumpCurvevariable]="pumpCurvevariable" [filterStartDate]="filterStartDate" [filterEndDate]="filterEndDate"></app-wellvariables>
  </div>
</div>
