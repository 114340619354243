<mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline" #field>
  <mat-select id="searchboxlist" class="searchdropdown" [placeholder]="placeholder" [panelClass]="'dls-panel'" #matselectedBranc
    (selectionChange)="onSelectionChange($event)" [hideSingleSelectionIndicator]="true" [(value)]="selectedvalue">
    <div class="mat-select-search-inner" style="position: sticky; top: 0; z-index: 1;">
      <input type="text" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt"
        (input)="onSearchChange($event,matselectedBranc)" [placeholder]="searchPlaceholder" class="searchBox">
    </div>
    <mat-option *ngFor="let option of FilterData" [value]="option.externalId"> {{option.Name}}</mat-option>
  </mat-select>
</mat-form-field>
