import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validator, Validators, FormControl } from '@angular/forms';
import { WellTolaranceModel, TabOptionsEnum } from '../model';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { ESPCondition, ESPparameterType, JPCondition, JPparameterType, PCPCondition, PCPparameterType, operationType, ESPparameterTypeofCondition, JPparameterTypewithCondition, PCPparameterTypewithCondition, TolType } from '../alsFielddata'
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { HierarchyService } from '../../services/hierarchy.service';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

//


@Component({
  selector: 'app-alstolerance',
  templateUrl: './alswelltolerance.component.html',
  styleUrls: ['./alswelltolerance.component.css']
})
export class AlswelltoleranceComponent {
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP
  public tolerancegridData: WellTolaranceModel[] = [];
  private subs: Subscription[] = [];
  public sdmPropertyList: any[] = [];
  public operationTypeList: any[] = operationType;
  public filteredoperationTypeList: any[] = operationType;
  public tolerancedata: WellTolaranceModel;
  public selectedrows: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public fieldList: any;
  public hidetype = true;
  isOnlineParameter = false;

  public selectedField: string;
  public selectedCondition: string;
  public conditionList = ESPCondition;
  public parameterList = ESPparameterType;
  public parameterListOfselectedCondition = ESPparameterTypeofCondition;
  public parameterListOfCondition = ESPparameterTypeofCondition;
  welltripsuffix = "PostTrip";

  public timeseriesdata: any;
  formgroup: FormGroup;
  timeseriesid: string | undefined;
  unit: string | undefined;
  public wellPadList: any;
  public filterWellList: any;
  public selectedWell: string;
  public selectedWellPad: string;
  public formPadList: any;
  public formwellList: any;
  public toltype = TolType;
  public timeframelogic = "";
  public metadata: any;
  checktype: string;
  toltypelist = TolType;
  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date Time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private hierarchyService: HierarchyService,
    private dateHelper: DateHelper,
  ) {
    this.setRecordValue();
  }

  ngOnInit() {
    this.setformgroupData();
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
  }


  setRecordValue(data: any = undefined) {
    this.isOnlineParameter = false;
    if (data == undefined) {
      this.tolerancedata = {
        condition: '', criticality: '', notificationType: '', operationType: '', parameterType: '',
        status: 'Active', userRole: '', value: 0, field: this.selectedField, well: this.selectedWell, wellpad: this.selectedWellPad
      }
    }
    else {
      //data.startdate = this.date.setDate(this.date.getDate() - 1).toLocaleString();
      let condition = this.conditionList.filter((x: any) => x.name.toLowerCase() == data.condition.toLowerCase())[0]?.value;
      this.selectedCondition = condition;
      let parameter = '';
      let type;
      this.setparameterList();

      if (data.parameterType != undefined) {
        parameter = this.parameterListOfselectedCondition.filter((x: any) => x.name.toLowerCase() == data.parameterType.toLowerCase())[0]?.value;
      }
      if (data.type != undefined) {
        type = this.toltypelist.filter((x: any) => x.name.toLowerCase() == data.type.toLowerCase())[0]?.value;
        this.isOnlineParameter = type == "onlinevalue" ? true : false;
      }
      let operationlocType = '';
      if (data.operationType != undefined) {
        operationlocType = data.operationType == "DATANOTAVAILABLE" ? "DATANOTAVAILABLE" : this.operationTypeList.filter((x: any) => x.name.toLowerCase() == data.operationType.toLowerCase())[0]?.value;
      }
      let status = data.status == 'Active' ? 'Active' : '';
      this.unit = data.unit;
      this.setOperationType(operationlocType);

      this.startdate = this.dateHelper.convertStringToDate(data.timestamp);
      this.timeframelogic = data.timeframelogic;
      this.metadata = data.metadata;

      this.tolerancedata = {
        startdate: data.startdate, condition: condition, criticality: '', notificationType: '', operationType: operationlocType,
        parameterType: parameter, status: status, userRole: '', value: data.value, unit: data.unit,
        field: this.selectedField, wellpad: this.selectedWellPad, well: this.selectedWell, timestamp: data.timestamp, type: type
      }
      this.unit = this.tolerancedata.unit;

    }
  }
  setparameterList() {
    if (this.selectedtab == this.tabOptions.ESP) {
      this.conditionList = ESPCondition;
      this.parameterList = ESPparameterType;
      this.parameterListOfselectedCondition = ESPparameterTypeofCondition.filter((list: any) => list.condition == this.selectedCondition);
      this.parameterListOfCondition = ESPparameterTypeofCondition;
    }
    else if (this.selectedtab == this.tabOptions.JP) {
      this.conditionList = JPCondition;
      this.parameterList = JPparameterType;
      this.parameterListOfselectedCondition = JPparameterTypewithCondition.filter((list: any) => list.condition == this.selectedCondition);;
      this.parameterListOfCondition = JPparameterTypewithCondition;
    }
    else if (this.selectedtab == this.tabOptions.PCP) {
      this.conditionList = PCPCondition;
      this.parameterList = PCPparameterType;
      this.parameterListOfselectedCondition = PCPparameterTypewithCondition.filter((list: any) => list.condition == this.selectedCondition);;
      this.parameterListOfCondition = PCPparameterTypewithCondition;
    }
  }

  setOperationType(OperationType: any) {
    if (this.tolerancedata.condition == 'piCalibratedProductivityIndexDrop' || this.tolerancedata.condition == 'PumpWearFactor') {
      this.filteredoperationTypeList = this.operationTypeList;
    } else {
      if (OperationType == "DATANOTAVAILABLE") {
        this.filteredoperationTypeList = [];
        this.filteredoperationTypeList.push({ "name": "DATANOTAVAILABLE", "value": "DATANOTAVAILABLE" });
        this.formgroup.get('value')?.clearValidators();
        this.formgroup.get('value')?.disable();
        this.formgroup.get('value')?.updateValueAndValidity();
      }
      else if ((this.selectedCondition == 'TripComparison')) {
        this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == 'value')
      }
      else if (this.unit == "%") {
        this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == '%')
      }
      else {
        this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == 'value')

      }
    }
  }
  public setformgroupData() {
    this.formgroup = this.fb.group({
      //startdate: [this.tolerancedata.startdate, Validators.required],
      field: new FormControl({ value: this.tolerancedata.field, disabled: this.formMode == "Edit" }, Validators.required),
      wellpad: new FormControl({ value: this.tolerancedata.wellpad, disabled: this.formMode == "Edit" }, Validators.required),
      well: new FormControl({ value: this.tolerancedata.well, disabled: this.formMode == "Edit" }, Validators.required),
      condition: new FormControl({ value: this.tolerancedata.condition, disabled: this.formMode == "Edit" }, Validators.required),
      parameterType: new FormControl({ value: this.tolerancedata.parameterType, disabled: this.formMode == "Edit" }, Validators.required),
      value: [this.tolerancedata.value, Validators.required],
      operationType: [this.tolerancedata.operationType, Validators.required],
      status: [this.tolerancedata.status],
      type: new FormControl({ value: this.tolerancedata.type, disabled: this.formMode == "Edit" }),
    });
    this.setEditFormDefault();
    if (this.isOnlineParameter == true)
      this.formgroup.get('status')?.disable();
  }
  selectionchange(event: any) {
    //console.log(event);

  }
  onChange($event: any) {
    //console.log($event.value);
    this.selectedtab = $event.value;
    this.selectedCondition = '';
    this.setparameterList();
    this.selectedField = '';
    this.tolerancegridData = [];
    this.clear();
    this.hierarchyComponent.clear();

  }

  ontypechange(event: any) {
    this.isOnlineParameter = event.value == "onlinevalue" ? true : false;
    this.loaddetailsofParameter(event.value);
  }

  loaddetailsofParameter(type: any = undefined) {
    let timeseriesexternaID = this.getTimeseriesexternaID(type);
    if (timeseriesexternaID == "") {
      let condition = this.formgroup.get("condition")?.value;
      let parameter = this.formgroup.get("parameterType")?.value;
      this.formgroup.controls.parameterType.setValue("");
      this.loader.hideLoader();
      const alert: SlbMessage = {
        target: 'modal',
        severity: SlbSeverity.Info,
        //summary: 'Summary',
        detail: 'Creation of condition ' + condition + ' with parameter ' + parameter + ' is not allowed',
      };
      this.messageService.add(alert);
    }
    else {
      this.apiService.getTsId(timeseriesexternaID).then(data => {
        if (data.items.length > 0) {
          this.loader.hideLoader();
          let response = data?.items[0];
          this.timeseriesid = response?.id.toString();
          this.unit = response.unit;
          if (this.selectedCondition != 'piCalibratedProductivityIndexDrop' && this.selectedCondition != 'PumpWearFactor') {
            let metadata = response.metadata;
            if (metadata) {
              this.timeframelogic = metadata.TimeFrameLogic;
              this.checktype = metadata.CheckType;
              this.metadata = metadata;
              debugger;
              if (metadata["OperationType"] == "DATANOTAVAILABLE") {
                this.filteredoperationTypeList = [];
                this.filteredoperationTypeList.push({ "name": "DATANOTAVAILABLE", "value": "DATANOTAVAILABLE" });
                this.formgroup.get('value')?.clearValidators();
                this.formgroup.get('value')?.disable();
                this.formgroup.get('value')?.updateValueAndValidity();
              }
              else if ((this.selectedCondition == 'TripComparison')) {
                this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == 'value')
              }
              else if (this.checktype == "OnlineCheck" || this.checktype == "ValueCheck") {
                this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == 'value')
              }
              else {
                this.filteredoperationTypeList = this.operationTypeList.filter(item => item.type == '%')
              }

              this.formgroup.controls.status.setValue(metadata["ParameterToggle"] == "ON" ? "Active" : "");
              this.formgroup.controls.operationType.setValue(metadata["OperationType"]);
            }
          }
        }
        else {
          this.loader.hideLoader();
          this.timeseriesid = undefined;
          const alert: SlbMessage = {
            target: 'modal',
            severity: SlbSeverity.Error,
            //summary: 'Summary',
            detail: '(' + timeseriesexternaID + ') Timeseries not found. ',

          };
          this.messageService.add(alert);
        }

      })
    }
  }

  onparameterchange(event: any) {
    this.sdmPropertyList = [];

    this.filteredoperationTypeList = this.operationTypeList;
    this.formgroup.controls.value.setValue(0);
    this.formgroup.controls.type.setValue("");
    this.formgroup.controls.operationType.setValue("");
    let selectedWell = this.formgroup.get("well")?.value;
    if ((event.value == 'drop') && (this.selectedCondition == 'PumpWearFactor')) {
      this.formgroup.controls.operationType.setValue("Dec");
      this.formgroup.controls.operationType.disable();
    } else if ((event.value == 'dropvalue') && (this.selectedCondition == 'PumpWearFactor')) {
      this.formgroup.controls.operationType.setValue("");
      this.formgroup.controls.operationType.disable();
    }
    let filterwelllist = this.formwellList.filter((X: any) => X.externalId == selectedWell)
    this.loader.showLoader();
    this.getExternalId(filterwelllist).subscribe((list: any) => {
      this.loader.hideLoader();
      let condition = (this.formgroup.get("condition")?.value).toLowerCase();
      let parameter = this.formgroup.get("parameterType")?.value.toLowerCase();
      let fieldname = (this.formgroup.get("parameterType")?.value + this.formgroup.get("condition")?.value).toLowerCase();
      let externalidlist = this.sdmPropertyList.filter((x: any) => x.keyvalue.startsWith(fieldname));
      if (parameter.endsWith(this.welltripsuffix.toLowerCase()) && condition == "rodfailureposttrip")
        condition = "rodfailure"
      if (externalidlist.length == 0)
        externalidlist = this.sdmPropertyList.filter((x: any) => x.value.toLowerCase().includes(condition) && x.value.toLowerCase().includes(parameter));
      if (externalidlist.length > 1) {
        this.toltype = this.toltypelist.filter(obj1 =>
          externalidlist.some(obj2 => obj2.keyvalue.includes(obj1.value))
        );
        const containsgradual = this.toltype.some(obj => obj.value === "gradualtol");
        this.toltype = this.toltype.filter(obj => {
          if (containsgradual) {
            return obj.value !== "tol";
          }
          return true;
        });
        this.hidetype = false;
      }
      else {
        this.hidetype = true;
        this.formgroup.get('type')?.clearValidators();
        this.loaddetailsofParameter();
      }
    });
  }

  save(form: any) {
    this.loader.showLoader();
    if (this.formgroup.valid) {

      this.tolerancedata.condition = this.formgroup.get("condition")?.value;
      this.tolerancedata.value = this.formgroup.get("value")?.value;
      this.tolerancedata.criticality = this.formgroup.get("criticality")?.value;
      this.tolerancedata.notificationType = this.formgroup.get("notificationType")?.value;
      this.tolerancedata.operationType = this.formgroup.get("operationType")?.value;
      this.tolerancedata.parameterType = this.formgroup.get("parameterType")?.value;
      this.tolerancedata.userRole = this.formgroup.get("userRole")?.value;
      this.tolerancedata.status = this.formgroup.get("status")?.value;
      let selectedWell = this.formgroup.get("well")?.value;
      this.loader.showLoader();

      let timeseriesexternaID = this.getTimeseriesexternaID(undefined);
      if (this.timeseriesid != undefined) {
        let timeseriesid = Number(this.timeseriesid);
        let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
        if (this.formMode == "Edit")
          timestamp = Date.parse(String(this.tolerancedata.timestamp));

        //let timestamp = Date.parse(this.startdate._d.toDateString());
        let datapoint = { id: timeseriesid, datapoints: [{ timestamp: timestamp, value: this.tolerancedata.value }] }
        this.metadata["ParameterToggle"] = this.tolerancedata.status == "Active" || this.tolerancedata.status.toString() == "true" ? "ON" : "OFF";
        this.metadata["OperationType"] = this.tolerancedata.operationType

        let promises: Promise<any>[] = [
          this.apiService.updateTimeseriesMetadata(timeseriesid, this.metadata)
        ];
        if (this.tolerancedata.operationType.toString() != "DATANOTAVAILABLE") {
          promises.push(this.apiService.insertTimeSeriesData(datapoint))
        }
        Promise.all(promises)
          .then((results: any[]) => {
            this.loader.hideLoader();
            if (this.selectedWell != undefined && this.selectedWell != "")
              this.loadGridData();
            this.sdmPropertyList = [];
            this.startdate = this.todaysdate;
            if (this.formMode == 'Edit') {
              const alert: SlbMessage = {
                target: 'modal',
                severity: SlbSeverity.Success,
                summary: 'Updated',
                detail: 'Record Updated Successfully.',
              };
              this.messageService.add(alert);
            }
          })
          .catch((error) => {
            console.error("At least one promise rejected:", error);
          });
      }
      else {
        this.loader.hideLoader();
        this.timeseriesid = undefined;
        const alert: SlbMessage = {
          target: 'modal',
          severity: SlbSeverity.Error,
          //summary: 'Summary',
          detail: 'Creation of condition ' + this.tolerancedata.condition + ' with parameter ' + this.tolerancedata.parameterType + ' is not allowed',

        };
        this.messageService.add(alert);
      }
    }
  }
  onConditionchange(event: any) {
    this.selectedCondition = event.value;
    this.timeseriesdata = undefined;
    this.setparameterList();

    this.formgroup.controls.status.setValue("Active");
    this.formgroup.controls.status.enable();
    this.formgroup.controls.operationType.setValue("");
    this.formgroup.controls.operationType.enable();
    this.formgroup.controls.parameterType.setValue("");
    this.formgroup.controls.parameterType.enable();
    this.formgroup.controls.value.setValue(0);
    this.hidetype = true;
    this.timeframelogic = ""
    this.setAddFormdefault();
    this.unit = undefined;
  }

  setAddFormdefault() {
    if (this.selectedCondition == 'piCalibratedProductivityIndexDrop') {
      this.formgroup.controls.status.setValue("");
      this.formgroup.controls.status.disable();
      this.formgroup.controls.operationType.setValue("Dec");
      this.formgroup.controls.operationType.disable();
      this.formgroup.controls.parameterType.disable();
      this.onparameterchange({ value: '' });
    }
    if (this.selectedCondition == 'PumpWearFactor') {
      this.formgroup.controls.status.setValue("");
      this.formgroup.controls.status.disable();
      this.formgroup.controls.operationType.disable();
    }
    if ((this.selectedCondition == 'TripComparison')) {
      this.formgroup.controls.operationType.disable();
    }
  }

  setEditFormDefault() {
    if (this.tolerancedata.condition == 'piCalibratedProductivityIndexDrop') {
      this.formgroup.controls.status.disable();
      this.formgroup.controls.operationType.setValue("Dec");
      this.formgroup.controls.operationType.disable();
    } else if (this.tolerancedata.condition == 'PumpWearFactor') {
      this.formgroup.controls.status.disable();
      if (this.tolerancedata.parameterType == 'drop') {
        this.formgroup.controls.operationType.setValue("Dec");
      }
      this.formgroup.controls.operationType.disable();
    }
    else if ((this.selectedCondition == 'TripComparison')) {
      this.formgroup.controls.operationType.disable();
    }
  }

  getTimeseriesexternaID(typestring: any): any {
    let fieldname = '';
    let condition = this.formgroup.get("condition")?.value.toLowerCase();
    let parameter = this.formgroup.get("parameterType")?.value.toLocaleLowerCase();

    let typetext = typestring;

    fieldname = (this.formgroup.get("parameterType")?.value + this.formgroup.get("condition")?.value + typetext).toLowerCase();
    let externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];

    if (parameter.endsWith(this.welltripsuffix.toLowerCase()) && condition == "rodfailureposttrip")
      condition = "rodfailure"
    if (externalid == undefined && fieldname == 'currentshaftbrokentol') {
      fieldname = 'currentshafttbrokentol';
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined) {
      fieldname = (this.formgroup.get("parameterType")?.value + this.formgroup.get("condition")?.value + "Value").toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if ((externalid == undefined) && (this.formgroup.get("parameterType")?.value == 'curHighLmt') && (this.selectedCondition == 'TripComparison')) {
      fieldname = ("curHigLmt".toLowerCase() + this.formgroup.get("condition")?.value + "tol").toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined) {
      fieldname = (this.formgroup.get("parameterType")?.value).toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined && condition == "espvibration".toLowerCase() && parameter == "vibration") {
      fieldname = (this.formgroup.get("parameterType")?.value + "Value").toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined && (condition == "highMwt".toLowerCase() || condition == "picalibratedproductivityindexdrop")) {
      fieldname = (this.formgroup.get("condition")?.value).toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined && this.formgroup.get("condition")?.value == 'NozzleBroken') {
      fieldname = (this.formgroup.get("parameterType")?.value + "NozleBroken" + typetext).toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if ((externalid == undefined) && (this.formgroup.get("parameterType")?.value == 'drop') && (this.selectedCondition == 'PumpWearFactor')) {
      fieldname = ('calibrated' + this.formgroup.get("condition")?.value + this.formgroup.get("parameterType")?.value).toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if ((externalid == undefined) && (this.formgroup.get("parameterType")?.value == 'dropvalue') && (this.selectedCondition == 'PumpWearFactor')) {
      fieldname = (this.formgroup.get("condition")?.value + this.formgroup.get("parameterType")?.value).toLowerCase();
      externalid = this.sdmPropertyList.filter((x: any) => x.keyvalue == fieldname)[0];
    }
    if (externalid == undefined)
      externalid = this.sdmPropertyList.filter((x: any) => x.value.toLowerCase().includes(parameter) && x.value.toLowerCase().includes(condition))[0];

    if (externalid == undefined) {
      return "";
    }
    //return this.selectedField + ":" + this.formgroup.get("condition")?.value + ":Tol:" + this.formgroup.get("parameterType")?.value;
    return externalid.value;
  }

  gettoleranceviewID(): string {
    let id = "AlsWell" + this.capitalize(this.selectedtab.toLowerCase()) + "Tolerances";
    //let id = "AlsWellEspTolerances";
    return id;
  }

  getabsoluteviewID(): string {
    //let id = "AlsWellEspAbsoluteValues";
    let id;
    if (this.selectedtab == this.tabOptions.PCP)
      id = "ALSWell" + this.selectedtab + "AbsoluteValues";
    else
      id = "AlsWell" + this.capitalize(this.selectedtab.toLowerCase()) + "AbsoluteValues";
    return id;
  }
  capitalize(str: string) {
    return str && str[0].toUpperCase() + str.slice(1);
  }

  onfieldChange(event: any) {
    this.filterWellList = [];
    this.selectedWell = ""
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
  }
  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    //this.getWellwithPumpType(event.value);
  }
  onFilterWellChange(event: any) {
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.well;
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;

    if (this.filterWellList == undefined || this.filterWellList.length == 0)
      this.filterWellList = event.allFieldwells;
    this.loadGridData();
  }

  onformfieldChange(event: any) {
    this.loader.showLoader();
    this.subs.push(this.hierarchyService.getWellpad(event.value).subscribe((data: any) => {
      this.loader.hideLoader();
      this.formPadList = data;
    }));
  }
  onformwellPadChange(event: any) {
    this.getWellwithPumpType(event.value, true);
  }
  onformwellChange(event: any) {
    this.formgroup.controls.operationType.setValue("");
    this.formgroup.controls.parameterType.setValue("");
  }
  getWellwithPumpType(value: string, isFormfield = false) {
    let lifttypeTimeserieslist: any[] = [];
    let listofallwell: { externalId: string; Name: string; liftType: string }[] = []
    let lifttypedata: any[] = [];
    this.loader.showLoader();
    this.subs.push(this.hierarchyService.getWelldataWithProperties(value).subscribe((data: any) => {
      let version = environment.cogniteSDMVersion
      let space = environment.cogniteSpace;
      data.forEach((item: any) => {
        let properties = item.properties;
        if (properties !== undefined) {
          let liftType = properties[space]["Well/" + version].liftType;
          listofallwell.push({ externalId: item.externalId, Name: properties[space]["Well/" + version].name.toString(), liftType: String(liftType) })
          if (liftType != undefined)
            lifttypeTimeserieslist.push({ "externalId": String(liftType) })
        }

      });
      this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist).then((timeseriesdatapoints: any) => {
        this.loader.hideLoader();
        timeseriesdatapoints.forEach((datapoint: any) => {
          if (datapoint.datapoints.length > 0) {
            datapoint.datapoints.forEach((data: any) => {
              if (data != undefined && String(data.value).toUpperCase() == this.selectedtab) {
                lifttypedata.push(datapoint.externalId)

              }
            })
          }
        })
        if (isFormfield) {
          this.formwellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
        }
        else {
          this.filterWellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
          //this.selectedwelllist = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
          //this.loadGridData();
        }

      })
    }));
  }
  getExternalId(welllist: any): Observable<any> {
    let version = environment.cogniteSDMVersion
    let welllistfilter = welllist.map((x: any) => x.externalId);
    let viewid = this.gettoleranceviewID();
    let absoluteviewid = this.getabsoluteviewID()
    let space = environment.cogniteSpace;
    let filter = {
      equals: {
        property: ["well"],
        "value": { "space": space, "externalId": welllistfilter[0] }
      }
    };
    let absolutefilter = {
      equals: {
        property: ["well"],
        "value": { "space": space, "externalId": welllistfilter[0] }
      }
    };
    let sources = [
      this.apiService.getInstance(viewid, filter, version),

    ];
    sources.push(this.apiService.getInstance(absoluteviewid, absolutefilter, version));

    this.loader.showLoader();
    let externalidlist: any[] = [];
    return forkJoin(sources).pipe(map((instancedata: any) => {
      let toleranceresult = instancedata[0];


      externalidlist = this.getexternalidlist(toleranceresult, space, viewid, version);
      let absoluteresult = instancedata[1];
      externalidlist = externalidlist.concat(this.getexternalidlist(absoluteresult, space, absoluteviewid, environment.cogniteSDMVersion));
      return externalidlist;
    }));
  }
  getexternalidlist(results: any, space: string, viewid: string, version: string): any {
    let externalidlist: any[] = [];
    let properties = results.items == undefined ? results?.properties : results.items[0]?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      delete externaliddata["well"]
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map(function (key) {
        return { "keyvalue": key.toLowerCase(), "value": externaliddata[key] };
      }));
      //console.log(this.sdmPropertyList);
      let values = Object.values(externaliddata);
      externalidlist = values.map(value => ({ "externalId": value }));
    }
    return externalidlist;
  }
  async loadGridData() {
    if (this.selectedWell != undefined && this.selectedWell != "") {
      this.tolerancegridData = [];
      this.sdmPropertyList = [];
      let version = environment.cogniteSDMVersion

      let viewid = this.gettoleranceviewID();
      let absoluteviewid = this.getabsoluteviewID()
      let space = environment.cogniteSpace;
      let filter = {
        equals: {
          property: ["well"],
          "value": { "space": space, "externalId": this.selectedWell }
        }
      };
      let absolutefilter = {
        equals: {
          property: ["well"],
          "value": { "space": space, "externalId": this.selectedWell }
        }
      };
      let sources = [
        this.apiService.getInstance(viewid, filter, version),

      ];
      sources.push(this.apiService.getInstance(absoluteviewid, absolutefilter, environment.cogniteSDMVersion));

      this.loader.showLoader();
      forkJoin(sources).subscribe((instancedata: any) => {
        let toleranceresult = instancedata[0];

        let externalidlist: any[] = [];
        externalidlist = this.getexternalidlist(toleranceresult, space, viewid, version);
        let absoluteresult = instancedata[1];
        externalidlist = externalidlist.concat(this.getexternalidlist(absoluteresult, space, absoluteviewid, environment.cogniteSDMVersion));

        if (externalidlist.length > 0) {
          this.getGridData(externalidlist);
          this.loader.hideLoader();
        }
        else {
          this.loader.hideLoader();
          const alert: SlbMessage = {
            target: 'modal',
            severity: SlbSeverity.Info,
            //summary: 'Summary',
            detail: 'No data available to show',

          };
          this.messageService.add(alert);
        }

      })
    }
  }
  async get_ts_data_in_parallel(externalidlist: any, latestdata = true) {
    let max_ts_size = 99
    let results: any[] = [];
    let promises = [];
    for (let index = 0; index < externalidlist.length; index += max_ts_size) {
      const batch = externalidlist.slice(index, index + max_ts_size);

      // Store promises for parallel execution
      if (latestdata == true) {
        promises.push(
          this.apiService.getLatestTimeseriesData(
            batch,
          ).catch(error => {
            console.error("Error in getting data:", error);
            return []; // Return an empty array if there's an error to continue processing the rest
          })
        );
      }
      else {
        promises.push(
          this.apiService.getTimeseries(
            batch,
          ).catch(error => {
            console.error("Error in getting data:", error);
            return []; // Return an empty array if there's an error to continue processing the rest
          })
        );
      }
    }
    try {
      // Wait for all promises to resolve
      const batchResults = await Promise.all(promises);

      // Flatten the results array
      results = batchResults.flat();
      return results;
    } catch (error) {
      console.error("Error in processing batches:", error);
    }
    return [];
  }

  public async getGridData(externalidlist: any[]) {
    let latestdata = await this.get_ts_data_in_parallel(externalidlist);
    let details = await this.get_ts_data_in_parallel(externalidlist, false);
    let timeseriesdatapoints = latestdata;
    this.timeseriesdata = details
    this.tolerancegridData = [];
    const toleranceRecords: any[] = [];
    let sequence = 0;
    timeseriesdatapoints.forEach((datapoint: any) => {
      if (datapoint.datapoints.length > 0) {
        let metadata = this.timeseriesdata.filter((x: any) => x.id == datapoint.id)[0].metadata;
        let idparams = datapoint.externalId.split(":");
        let condition = '';
        if (idparams[1].toLowerCase() == "dhgfailure")
          return;
        if ((idparams[1] == 'EspPiDrop') || (idparams[1] == 'JpPiDrop')) {
          condition = this.conditionList.filter((x: any) => x.value.toLowerCase() == 'piCalibratedProductivityIndexDrop'.toLowerCase())[0]?.name;
        } else if (idparams[1] == 'EspWear') {
          condition = this.conditionList.filter((x: any) => x.value.toLowerCase() == 'PumpWearFactor'.toLowerCase())[0]?.name;
        }
        else if (idparams[1].toLowerCase() == 'jppumpchoking') {
          condition = this.conditionList.filter((x: any) => x.value.toLowerCase() == 'pumpchoking'.toLowerCase())[0]?.name;
        }
        else {
          condition = this.conditionList.filter((x: any) => x.value.toLowerCase() == idparams[1].toLowerCase().trim())[0]?.name;
        }
        let parameterType = '';
        let gradualsuffix = datapoint.externalId.toLowerCase().includes("gradual");
        let onlinesuffix = datapoint.externalId.toLowerCase().includes("online");
        let toltype: string = '';
        let checktype = metadata.CheckType;
        let parameter = idparams[3].toLowerCase().trim();
        if ((checktype != undefined && checktype.toLowerCase() == "onlinecheck") || onlinesuffix == true) {
          parameter = parameter.replace('online', '');
          toltype = "Online"
        }
        else if ((checktype != undefined && checktype.toLowerCase() == "gradualcheck") || gradualsuffix == true) {
          parameter = parameter.replace('gradual', '');
          toltype = "Gradual"
        }
        else if ((checktype != undefined && checktype.toLowerCase() == "valuecheck"))
          toltype = 'Value'
        else
          toltype = '%'
        if (idparams[1] == 'EspWear') {
          parameterType = idparams[2] == 'Value' ? 'Value' : 'Drop'
        } else {
          parameterType = this.parameterListOfCondition.filter((x: any) => x.value.toLowerCase() == parameter)[0]?.name;
        }
        if (idparams[3].endsWith(this.welltripsuffix) && condition == "Rod Failure" && (parameterType == "Frequency" || parameterType == "Torque")) {
          let postwelltripcond = this.conditionList.filter((x: any) => x.value.toLowerCase() == (idparams[1] + this.welltripsuffix).toLowerCase())[0]?.name;
          condition = idparams[3].endsWith(this.welltripsuffix) ? postwelltripcond : condition;
        }

        let optype = '';
        let operration = this.operationTypeList;
        if (toltype == "%" || toltype == "Gradual")
          optype = this.operationTypeList.filter(item => item.type == "%" && item.value.toLowerCase() == metadata.OperationType.toLowerCase())[0]?.name
        //this.operationTypeList.filter((x: any) =>x.type =="%" && x.value.toLowerCase() == metadata.OperationType.toLowerCase())[0]?.name;
        else
          optype = this.operationTypeList.filter((x: any) => x.type == "value" && x.value.toLowerCase() == metadata.OperationType.toLowerCase())[0]?.name

        let operationlocType = metadata["OperationType"] == "DATANOTAVAILABLE" ? "DATANOTAVAILABLE" : optype;
        let logicdetails = metadata["LogicDetails"] !=undefined && metadata["LogicDetails"] !="NA" ? metadata["LogicDetails"] : "";
        let timeframelogic = metadata["TimeFrameLogic"] + (logicdetails ? " " + logicdetails : "");

        datapoint.datapoints.forEach((data: any) => {
          let item = {
            sequence: sequence, id: datapoint.id, condition: condition != undefined ? condition : "-", startdate: moment(data.timestamp).format("DD-MM-YYYY"), operationType: operationlocType, parameterType: parameterType, status: metadata["ParameterToggle"] == "ON" ? "Active" : "Shut", value: data.value,
            unit: datapoint.unit, valueunit: data.value + " " + datapoint.unit, timestamp: data.timestamp, type: toltype, timeframelogic: timeframelogic, metadata: metadata
          };
          sequence += 1;
          toleranceRecords.push(item)

        });
      }
    });
    this.tolerancegridData = addSortableDates(toleranceRecords, this.kendoGrid);
  }

  public onAddrecordClick() {
    this.startdate = this.todaysdate;
    this.formMode = "Add";
    this.hidetype = true;
    this.formwellList = this.filterWellList;
    this.formPadList = this.wellPadList;
    this.parameterListOfselectedCondition = [];
    this.selectedCondition = "";
    this.setRecordValue();
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
      disableClose: true
    });
  }
  clear() {
    this.sdmPropertyList = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.tolerancegridData = [];
    this.wellPadList = [];
    this.filterWellList = [];
  }
  public onEditecordClick(dataItem: any) {
    this.formMode = "Edit";
    this.hidetype = false;
    //this.formgroup.controls['condition'].disable();
    this.formwellList = this.filterWellList;
    this.formPadList = this.wellPadList;
    this.setRecordValue(dataItem);
    this.setformgroupData();
    this.timeseriesid = dataItem.id;
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
      disableClose: true
    });
  }
  public statusCode(code: string): SafeStyle {
    let color = 'inherit'
    if (code.toLocaleLowerCase() == "shut")
      color = "red"
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }
  public criticalitycolorCode(code: string): SafeStyle {
    let color = 'red'
    if (code.toLocaleLowerCase() == "medium")
      color = "orange"
    else if (code.toLocaleLowerCase() == "low")
      color = "blue";
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  public ondelete(item: any) {
    this.openDialog(item);
  }

  public onsave(item: any) {
    if (this.formMode == "Edit") {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Previous values would be lost. Do you want to proceed with the changes ?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.save(item);
        }
        else {
          const dialogRef = this.dialog.open(this.addRecordDialog, {
            panelClass: 'update-range-dialog',
            disableClose: true
          });
        }
      });
    } else {
      this.save(item);
    }
  }

  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete: any[] = [];
    if (selectedrows == undefined) {
      let data = this.tolerancegridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          recortodelete.push({ id: record.id, inclusiveBegin: Date.parse(record.timestamp!) })
        }
      });
      console.log(data);
    }
    else
      recortodelete.push({ id: selectedrows.id, inclusiveBegin: Date.parse(selectedrows.timestamp) })
    this.loader.showLoader();
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.selectedrows = undefined;
      this.loadGridData();

    })

  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
