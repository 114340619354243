<div style="width: 98%;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{cardTitle}} <span class="text-white-50 fs-6">{{ unit ? '(' : '' }}{{unit}}{{ unit ? ')' :
                    '' }}</span></mat-card-title>
            <!-- <button slb-secondary-button type="button"  class="right-button">
          <span>Caputre Latest Well Tests </span>
        </button> -->
        </mat-card-header>
        <mat-card-content style="margin-top: 0px;">
            <div class="d-flex h-100 gap-3 mb-3" *ngIf="!isNanPercentage">
                <div class="d-flex flex-column col-4">
                    <highcharts-chart *ngIf="!isNanPercentage" class="w-100 h-100 d-block"
                        [Highcharts]="gaugeHighcharts" [constructorType]="gaugeChartConstructor"
                        [options]="gaugeChartOptions"></highcharts-chart>
                    <highcharts-chart *ngIf="isNanPercentage" class="w-100 h-100 d-block" [Highcharts]="gaugeHighcharts"
                        [constructorType]="gaugeChartConstructor" [options]="dummyChartOptions"></highcharts-chart>
                </div>
                <div class="d-flex flex-column col-8 align-self-end">
                    <div class="d-flex fs-1 mb-4">
                        <span class="px-2"> <mat-icon *ngIf="percentage < 0" [style.color]="getPercentageColor()"
                                svgIcon="arrow_down" style="height: 35px; width: 35px;" aria-hidden="false"></mat-icon>
                            <mat-icon *ngIf="percentage >= 0" [style.color]="getPercentageColor()" svgIcon="arrow_up"
                                style="height: 35px; width: 35px;" aria-hidden="false"></mat-icon>
                        </span>
                        <h1 *ngIf="loading" class="text-white-50 m-0" [style.fontSize]="'medium'">Loading...</h1>
                        <h1 *ngIf="!loading" [class]="isNanPercentage ? 'text-white-50 m-0' : 'm-0'"
                            [style.fontSize]="isNanPercentage ? 'medium' : ''"
                            [style.color]="isNanPercentage ? '' : getPercentageColor()"> {{ isNanPercentage ? '' :
                            percentage >=0 ? '+' : '-'}} {{ isNanPercentage ? 'No data available' : (diffrenceToShow)}}</h1>
                    </div>
                    <div class="d-flex h-100 ">
                        
                        <span class="px-4"></span>
                        <div class="d-flex flex-column mx-2">
                            <div class="text-white-50">Injection Rate</div>
                            <h4 class="m-0">{{formatNumber(injectionRateToday)}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isNanPercentage" class="align-content-center" [style.height]="'9.54em'">
                <h1 class="text-white-50 m-0 text-center" [style.fontSize]="'medium'">{{ loading ? 'Loading...' : 'No data available'}}</h1>
            </div>
            <div class="text-white-50" *ngIf="asOfDate" >Data as of {{asOfDate}}</div>
        </mat-card-content>
    </mat-card>

</div>