import { AfterViewInit, Directive, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { calculateRowsPerPage } from '../utils/calculate-table-height';

declare interface GridSettings {
  calculateRowsPerPage?: boolean;
  fixHeaders?: boolean;
  mainGridContainerId?: string;
  heightFragment?: number;
  marginHeader?: number;
}

@Directive({
  selector: '[appGridTransform]',
})
export class GridTransformDirective implements AfterViewInit, OnChanges {

  @Input('appGridTransform') gridSettings: GridSettings | '' = {};
  @Input() kendoGridBinding: any[];
  @Input() isFullscreen: boolean = false;

  private defaultRowsPerPage: number = 10;
  private settings: GridSettings = {};
  private columnsWithFixedWidth: any[] = [];
  private isLargeScreen: boolean = window.innerWidth >= 2560;

  constructor(private readonly grid: GridComponent, private readonly renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    this.settings = this.gridSettings !== '' ? this.gridSettings : {};

    if (this.grid)
      this.defaultRowsPerPage = this.grid.pageSize ?? 10;

    this.updateHeaders();
    this.setRowsPerPage();
    this.adjustColumnsWidth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kendoGridBinding?.firstChange === false && changes.kendoGridBinding?.previousValue !== changes.kendoGridBinding?.currentValue)
      this.setRowsPerPage();

    if (changes.isFullscreen?.firstChange === false && changes.isFullscreen?.previousValue !== changes.isFullscreen?.currentValue)
      setTimeout(() => this.setRowsPerPage(), 250);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.isLargeScreen = window.innerWidth >= 2560;
    this.setRowsPerPage();
    this.adjustColumnsWidth();
  }

  private updateHeaders() {
    if (this.settings?.fixHeaders) {
      const headers = document.querySelectorAll('.k-grid .k-grid-header .k-table-th:first-child[aria-colindex]');

      if (headers) {
        headers.forEach(header => {
          this.renderer.addClass(header, 'fix-header-border');
        });
      }
    }
  }

  private setRowsPerPage() {
    this.setDefaultRowsPerPage();

    if (this.grid && this.settings?.calculateRowsPerPage && this.settings?.mainGridContainerId) {
      const rowsPerPage = calculateRowsPerPage(document.getElementById(this.settings.mainGridContainerId), this.settings.heightFragment, 21, this.settings.marginHeader);
      const previousPageSize = Number(`${this.grid.pageSize}`);
      const totalRows = (this.grid.data as GridDataResult).total;
      this.grid.pageSize = rowsPerPage;

      if (this.grid.data && (totalRows > rowsPerPage || totalRows > previousPageSize))
        (this.grid.data as GridDataResult).data = this.kendoGridBinding.slice(0, rowsPerPage);
    }
  }

  private setDefaultRowsPerPage() {
    if (this.grid) {
      this.grid.pageSize = this.defaultRowsPerPage;
      const data = this.kendoGridBinding ?? [];

      (this.grid.data as GridDataResult).data = data.slice(0, this.defaultRowsPerPage);
    }
  }

  private adjustColumnsWidth() {
    if (this.grid?.columns) {
      const gridWidth = this.grid.wrapper.nativeElement.offsetWidth;
      const columnsArray = this.grid.columns.toArray();
      let totalFixedWidth = 0;
      const autoWidthColumns: any[] = [];
      const columnsWithFixedWidth: any[] = [];

      let index = 0;

      columnsArray.forEach((column: any) => {
        if (column?.width) {
          if (this.columnsWithFixedWidth.length === 0) {
            columnsWithFixedWidth.push(column);
            totalFixedWidth += parseInt(column.width, 10);
          } else {
            const originalWidth = this.columnsWithFixedWidth[index]?.width;
            totalFixedWidth += this.isLargeScreen ? parseInt(originalWidth, 10) * 2 : originalWidth;
          }

          index++;
        } else {
          autoWidthColumns.push(column);
        }
      });

      this.columnsWithFixedWidth = columnsWithFixedWidth;

      const remainingWidth = gridWidth - totalFixedWidth;
      const autoWidth = remainingWidth / autoWidthColumns.length;

      index = 0;

      columnsArray.forEach((column: any) => {
        if (column?.width) {
          const originalWidth = this.columnsWithFixedWidth[index]?.width;
          const newWidth = this.isLargeScreen ? parseInt(originalWidth, 10) * 2 : originalWidth;
          column.width = newWidth;
          index++;
        } else {
          column.width = autoWidth;
        }
      });

      this.grid.columnResize.emit();
    }
  }

}
