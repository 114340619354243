<div class="grid">
  <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15">
  </app-auto-refresh>
  <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" appGridTransform>
    <ng-template kendoGridToolbarTemplate>
      <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
        <button type="button" class="k-button k-grid-pdf k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid"
          [data]="gridData">
          <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
        </button>
        <button type="button" kendoGridPDFCommand>
          <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
        </button>
      </div>
    </ng-template>
    <kendo-grid-column field="welllinkedasset" title="Linked Asset" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="startdatetime_ts" title="Start" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdatetime }}</ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-date-time-filter [filter]="filter" valueField="startdatetime">
        </app-date-time-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="enddatetime_ts" title="End" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.enddatetime }}</ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-date-time-filter [filter]="filter" valueField="enddatetime">
        </app-date-time-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="type" title="Type" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="motherscc" title="Mother Solution Concentration Checkup" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="fitwirc" title="FIT Water injection rate Checkup" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="cdeviation" title="Concentration Deviation" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="correctedc" title="Corrected Concentration (ppm)" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="targetc" title="Target Concentration (ppm)" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="cthreshold" title="Concentration Threshold" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="fitpolyinjrc" title="FIT Polymer injection rate Checkup" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="rdilutratio" title="Recommended Dilution Ratio" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="rinjwrate" title="Recommended Injection Water Rate" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="rpolyrate" title="Recommended Polymer Rate " [width]="150"></kendo-grid-column>
    <kendo-grid-column field="backgroundc" title="Background Concentration (ppm)" [width]="80"></kendo-grid-column>
    <kendo-grid-excel [fileName]="title + '.xlsx'"></kendo-grid-excel>
    <kendo-grid-pdf [fileName]="title + '.pdf'" [allPages]="true"></kendo-grid-pdf>
  </kendo-grid>
</div>
