<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.ESP">ESP Calibration</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.JP">JP Calibration</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="false" [showClearButton]="false" (onWellPadChange)="onfilterwellPadChange($event)" [getListofAllWellsUnderField]="true" [screeId]="'inputcalibration'" (onWellchange)="onFilterWellChange($event)" [LiftTypeToloadWells]="this.selectedtab" (ondatechange)="onDateSelected($event)"></app-hierarchy>
  <slb-date-picker hint="" [(value)]="dateFilter" style="margin-left: 10px;" (dateChange)="ondatechange($event)" [maxValue]="dateFilterMax"></slb-date-picker>
  <span style="margin-left: 10px;"><button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base" type="button" (click)="clear()" [disabled]="false">
      <span>Clear</span>
    </button>
  </span>
</div>
<div class="page-content">
  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ selectedtab }} Calibration</mat-card-title>
        <div class="header-right-tools">
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="onAddrecordClick()">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add</span>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" kendoGridSelectBy="sequence" [filterable]="true" [sortable]="true" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="sequence" title="sequence" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="well" title="Well  Number" [width]="150"></kendo-grid-column>
          <kendo-grid-column field="startdate_ts" title="Start Date" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="startdate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column-group title="Reservoir Pressure">
            <kendo-grid-column field="rpfrom" title="From (psia)" [filterable]="false" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="rpto" title="To (psia)" [filterable]="false" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="rpstepsize" [width]="150" [title]="tabOptions.JP === selectedtab ? 'Range Division Count' : 'Step Size Count'" [filterable]="false"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Kn" [hidden]="selectedtab === tabOptions.ESP">
            <kendo-grid-column field="knfrom" title="From" [filterable]="false" [width]="100" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="knto" title="To" [filterable]="false" [width]="100" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="knstepsize" title="Step Size Count" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Ks" [hidden]="selectedtab === tabOptions.ESP">
            <kendo-grid-column field="ksfrom" title="From" [filterable]="false" [width]="100" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="ksto" title="To" [filterable]="false" [width]="100" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="ksstepsize" title="Step Size Count" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="PI" [hidden]="selectedtab === tabOptions.ESP">
            <kendo-grid-column field="pifrom" title="From (bbl/d/psi)" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="pito" title="To (bbl/d/psi)" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="pistepsize" title="Range Division Count" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column field="estimatedrp" [hidden]="selectedtab === tabOptions.JP" [filterable]="false" [width]="150" title="Last Reservoir Pressure (psia)"></kendo-grid-column>
          <kendo-grid-column-group title="Previous Model Value" [hidden]="selectedtab === tabOptions.ESP">
            <kendo-grid-column field="estimatedrp" title="Last Reservoir Pressure(psia)" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="estimatedkn" title="Last Kn" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="estimatedks" title="Last Ks" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
            <kendo-grid-column field="estimatedpi" title="Last PI (bbl/d/psi)" [filterable]="false" [width]="150" [hidden]="selectedtab === tabOptions.ESP"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column title="Action" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItem)">
                <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
              </a>
              <a slb-icon-button routerLink="." title="delete" (click)="ondelete(dataItem)">
                  <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="{{ selectedtab }} Calibration.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="{{ selectedtab }} Calibration.pdf" [allPages]="true">
            <kendo-grid-column field="well" title="Well  Number" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="startdate_ts" title="Start Date" [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column-group title="Reservoir Pressure">
              <kendo-grid-column field="rpfrom" title="From (psia)" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="rpto" title="To (psia)" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="rpstepsize" [width]="150" [title]="tabOptions.JP === selectedtab ? 'Range Division Count' : 'Step Size Count'"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="Kn" [hidden]="selectedtab === tabOptions.ESP">
              <kendo-grid-column field="knfrom" title="From" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="knto" title="To" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="knstepsize" title="Step Size Count" [width]="150"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="Ks" [hidden]="selectedtab === tabOptions.ESP">
              <kendo-grid-column field="ksfrom" title="From" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="ksto" title="To" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="ksstepsize" title="Step Size Count" [width]="150"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="PI" [hidden]="selectedtab === tabOptions.ESP">
              <kendo-grid-column field="pifrom" title="From (bbl/d/psi)" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="pito" title="To (bbl/d/psi)" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="pistepsize" title="Range Division Count" [width]="150"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column field="estimatedrp" [hidden]="selectedtab === tabOptions.JP"  [width]="150" title="Last Reservoir Pressure (psia)"></kendo-grid-column>
            <kendo-grid-column-group title="Previous Model Value" [hidden]="selectedtab === tabOptions.ESP">
              <kendo-grid-column field="estimatedrp" title="Last Reservoir Pressure(psia)" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="estimatedkn" title="Last Kn" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="estimatedks" title="Last Ks" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="estimatedpi" title="Last PI (bbl/d/psi)" [width]="150"></kendo-grid-column>
            </kendo-grid-column-group>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
    <ng-template #addRecordDialog>
      <mat-dialog-content style="height: auto; width: 700px;" class="update-range-dialog-content">
        <mat-card-title class="headerLabel">{{ formMode === 'Add' ? 'Add New': 'Update'}} Record</mat-card-title>
        <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
        <div class="form-container" style="margin-top: 30px;">
          <form (ngSubmit)='save(formgroup)' [formGroup]='formgroup'>
            <slb-date-picker hint="" [(value)]="startdate" label="Start Date Time" [disabled]="formMode === 'Edit'" [maxValue]="todaysdate"></slb-date-picker>
            <br>
            <mat-form-field slbFormField class="form-field" appearance="outline">
              <mat-label>Field</mat-label>
              <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field" (selectionChange)="onformfieldChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let field of fieldList" [value]="field.externalId">{{ field.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
              <mat-label>WellPad</mat-label>
              <mat-select placeholder="Well Pad" #input [panelClass]="'dls-panel'" formControlName='wellpad' (selectionChange)="onformwellPadChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let wellPad of formPadList" [value]="wellPad.externalId">{{ wellPad.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
              <mat-label>Well</mat-label>
              <mat-select placeholder="Select" formControlName='well' #input [panelClass]="'dls-panel'" (selectionChange)="onwellChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let well of wellList" [value]="well.externalId">{{ well.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <fieldset>
              <legend class="w-100 group-header" style="font-size: 18px;" >Reservoir Pressure Range</legend>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>From</mat-label>
                <input type="number" matInput formControlName="reservoirpfrom"><span>psia</span>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>To</mat-label>
                <input type="number" matInput formControlName="reservoirpto"><span>psia</span>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label> {{tabOptions.JP === selectedtab ? 'Range Division Count' : 'Step Size Count' }}</mat-label>
                <input type="number" matInput formControlName="reservoirstepsize">
              </mat-form-field>
            </fieldset>
            <fieldset *ngIf="selectedtab === tabOptions.JP">
              <legend class="w-100 group-header" style="font-size: 18px;" >PI</legend>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>From</mat-label>
                <input type="number" matInput formControlName="pifrom"><span>bbl/d/psi</span>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>To</mat-label>
                <input type="number" matInput formControlName="pito"><span>bbl/d/psi</span>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>Range Division Count</mat-label>
                <input type="number" matInput formControlName="pistepsize">
              </mat-form-field>
            </fieldset>
            <fieldset *ngIf="selectedtab === tabOptions.JP">
              <legend class="w-100 group-header" style="font-size: 18px;" >Kn </legend>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>From</mat-label>
                <input type="number" matInput formControlName="knfrom">
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>To</mat-label>
                <input type="number" matInput formControlName="knto">
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>Step Size Count</mat-label>
                <input type="number" matInput formControlName="knstepsize">
              </mat-form-field>
            </fieldset>
            <fieldset *ngIf="selectedtab === tabOptions.JP">
              <legend class="w-100 group-header" style="font-size: 18px;" >Ks</legend>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>From</mat-label>
                <input type="number" matInput formControlName="ksfrom">
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>To</mat-label>
                <input type="number" matInput formControlName="ksto">
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>Step Size Count</mat-label>
                <input type="number" matInput formControlName="ksstepsize">
              </mat-form-field>
            </fieldset>
          </form>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" class="update-range-dialog-actions">
        <mat-label matError [hidden]="!showerrormsg"> Please enter data of any one parameter set.</mat-label>
        <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
        <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="save(formgroup)">{{ formMode === 'Add' ? 'Create' : 'Update'}}</button>
      </mat-dialog-actions>
    </ng-template>
  </div>
</div>
