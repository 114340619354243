<div class="content-left-margin" style="height: 1px;"></div>
<div class="page-content">
  <mat-card class="example-card" id="card" autoHeight>
    <mat-card-header>
      <mat-card-title>File Search</mat-card-title>
    </mat-card-header>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="loading" class="spinner__loading loading-container">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div class="row" style="min-height: 0;">
        <div class="col-sm-12 col-md-3 col-lg-2">
          <mat-form-field slbFormField class="form-field" appearance="outline" #field style="padding-top: 0; width: 100%;">
            <mat-label></mat-label>
            <input type="text" matInput [(ngModel)]="searchValue">
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-2">
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="searchFiles()">
            <mat-icon svgIcon="search"></mat-icon>
            <span>Search</span>
          </button>
        </div>
      </div>
      <div>
        <kendo-grid #grid [kendoGridBinding]="files" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [selectable]="true" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', marginHeader: 450 }">
          <ng-template kendoGridToolbarTemplate>
            <div class="row">
              <h4 class="col-sm-10">Results</h4>
              <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="grid" [exportColumnRules]="exportColumnRules" [data]="files">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </div>
          </ng-template>
          <kendo-grid-column field="name" title="File Name"></kendo-grid-column>
          <kendo-grid-column field="date_ts" title="Date" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.date }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="date">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="type" title="Type" [width]="150"></kendo-grid-column>
          <kendo-grid-column field="action" title="Action" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a slb-icon-button routerLink="." title="Download" (click)="downloadFile(dataItem)">
                <mat-icon style="color: #6BA7FF;" svgIcon="download"></mat-icon>
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Files.xlsx">
            <kendo-excelexport-column field="name" title="File Name"></kendo-excelexport-column>
            <kendo-excelexport-column field="date" title="Date" [width]="150"></kendo-excelexport-column>
            <kendo-excelexport-column field="type" title="Type" [width]="150"></kendo-excelexport-column>
          </kendo-grid-excel>
          <kendo-grid-pdf fileName="Files.pdf" [allPages]="true">
            <kendo-grid-column field="name" title="File Name"></kendo-grid-column>
            <kendo-grid-column field="date" title="Date" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="type" title="Type" [width]="150"></kendo-grid-column>
          </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </mat-card-content>
  </mat-card>
</div>
