<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.ESP">ESP Calibration</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.JP">JP Calibration</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="false"
    [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [getListofAllWellsUnderField]="true"
    (onWellchange)="onFilterWellChange($event)" (onclear)="clear()" [LiftTypeToloadWells]="selectedtab"></app-hierarchy>
</div>
<div *ngIf="selectedtab === 'ESP'">
  <div class="page-content">
    <mat-card class="example-card">
      <mat-card-header>
        <div class="card-header-container">
          <mat-card-title>ESP Calibration</mat-card-title>
          <mat-card-title class="right-title">No of calibrated wells to be reviewed: {{ wellCount }}</mat-card-title>
        </div>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip #espKendoGrid [data]="espgridData" [groupable]="true" [group]="groups" [resizable]="true"
          [pageable]="true" [pageSize]="10" [filterable]="true" (dataStateChange)="espdataStateChange($event)"
          [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" [group]="state.group" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button" kendoGridExcelCommand>
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="key" title="" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="casenumber" title="" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="wellnumber" title="Well Number"></kendo-grid-column>
          <kendo-grid-column-group title="Calibration Match Parameters">
            <kendo-grid-column field="executedate_ts" title="Executed On Date" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.executedate }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="executedate">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="respressure" [title]="getFielTextWithUnit('Res Pressure', 'respressure', 'psig')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="liquidrate" [title]="getFielTextWithUnit('Liquid Rate', 'liquidrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="oilrate" [title]="getFielTextWithUnit('Oil Rate', 'oilrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="waterrate" [title]="getFielTextWithUnit('Water Rate', 'waterrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="gasrate" [title]="getFielTextWithUnit('Gas Rate', 'gasrate', 'MMscf/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="frictionfactor" [title]="getFielTextWithUnit('Friction Factor', 'frictionfactor')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="holdupfactor" [title]="getFielTextWithUnit('Hold Up Factor', 'holdupfactor')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pumpwearfactor" [title]="getFielTextWithUnit('Wear Factor', 'pumpwearfactor')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="dpfriction" [title]="getFielTextWithUnit('DP Friction', 'dpfriction', 'psi')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="dpgravity" [title]="getFielTextWithUnit('DP Gravity', 'dpgravity', 'psi')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pi" [title]="getFielTextWithUnit('PI', 'pi', 'STB/day/psi')"
              [width]="120"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column title="Click To Save" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoRadioButton type="radio" name="dataItem.wellnumber" id="dataItem.casenumber"
                (click)="onRadioClick(dataItem)">
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="ESP Calibration.xlsx">
            <kendo-grid-column field="wellnumber" title="Well Number"></kendo-grid-column>
            <kendo-grid-column-group title="Calibration Match Parameters">
              <kendo-excelexport-column field="executedate" title="Executed On Date" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="respressure" [title]="getFielTextWithUnit('Res Pressure', 'respressure', 'psig')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="liquidrate" [title]="getFielTextWithUnit('Liquid Rate', 'liquidrate', 'STB/day')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="oilrate" [title]="getFielTextWithUnit('Oil Rate', 'oilrate', 'STB/day')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="waterrate" [title]="getFielTextWithUnit('Water Rate', 'waterrate', 'STB/day')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="gasrate" [title]="getFielTextWithUnit('Gas Rate', 'gasrate', 'MMscf/day')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="frictionfactor" [title]="getFielTextWithUnit('Friction Factor', 'frictionfactor')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="holdupfactor" [title]="getFielTextWithUnit('Hold Up Factor', 'holdupfactor')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="pumpwearfactor" [title]="getFielTextWithUnit('Wear Factor', 'pumpwearfactor')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="dpfriction" [title]="getFielTextWithUnit('DP Friction', 'dpfriction', 'psi')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="dpgravity" [title]="getFielTextWithUnit('DP Gravity', 'dpgravity', 'psi')" [width]="120"></kendo-excelexport-column>
              <kendo-excelexport-column field="pi" [title]="getFielTextWithUnit('PI', 'pi', 'STB/day/psi')" [width]="120"></kendo-excelexport-column>
            </kendo-grid-column-group>
          </kendo-grid-excel>
          <kendo-grid-pdf fileName="ESP Calibration.pdf" [allPages]="true">
            <kendo-grid-column field="key" title="" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="wellnumber" title="Well  Number"></kendo-grid-column>
            <kendo-grid-column-group title="Calibration Match Parameters">
              <kendo-grid-column field="executedate" title="Executed On Date" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="respressure"
                [title]="getFielTextWithUnit('Res Pressure', 'respressure', 'psig')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="liquidrate"
                [title]="getFielTextWithUnit('Liquid Rate', 'liquidrate', 'STB/day')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="oilrate" [title]="getFielTextWithUnit('Oil Rate', 'oilrate', 'STB/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="waterrate" [title]="getFielTextWithUnit('Water Rate', 'waterrate', 'STB/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="gasrate" [title]="getFielTextWithUnit('Gas Rate', 'gasrate', 'MMscf/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="frictionfactor"
                [title]="getFielTextWithUnit('Friction Factor', 'frictionfactor')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="holdupfactor" [title]="getFielTextWithUnit('Hold Up Factor', 'holdupfactor')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pumpwearfactor" [title]="getFielTextWithUnit('Wear Factor', 'pumpwearfactor')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="dpfriction" [title]="getFielTextWithUnit('DP Friction', 'dpfriction', 'psi')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="dpgravity" [title]="getFielTextWithUnit('DP Gravity', 'dpgravity', 'psi')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pi" [title]="getFielTextWithUnit('PI', 'pi', 'STB/day/psi')"
                [width]="120"></kendo-grid-column>
            </kendo-grid-column-group>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="selectedtab === 'JP'">
  <div class="page-content">
    <mat-card class="example-card">
      <mat-card-header>
        <div class="card-header-container">
           <mat-card-title>JP Calibration</mat-card-title>
          <mat-card-title class="right-title">No of calibrated wells to be reviewed: {{ wellCount }}</mat-card-title>
        </div>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip #jpKendoGrid [data]="jpgridData" [groupable]="true" [group]="groups" [resizable]="true"
          [pageable]="true" [pageSize]="2" [sortable]="true" (dataStateChange)="jpdataStateChange($event)"
          [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" [group]="state.group" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div
              style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button"
                class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid"
                [appExportToExcel]="jpKendoGrid" [exportColumnRules]="exportColumnRules" [data]="jpgridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="wellnumber" title="Well Number"></kendo-grid-column>
          <kendo-grid-column-group title="Calibration Match Parameters">
            <kendo-grid-column field="executedate_ts" title="Executed On Date" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.executedate }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="executedate">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="respressure" [title]="getFielTextWithUnit('Res Pressure', 'respressure', 'psig')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="liquidrate" [title]="getFielTextWithUnit('Liquid Rate', 'liquidrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="oilrate" [title]="getFielTextWithUnit('Oil Rate', 'oilrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="waterrate" [title]="getFielTextWithUnit('Water Rate', 'waterrate', 'STB/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="gasrate" [title]="getFielTextWithUnit('Gas Rate', 'gasrate', 'MMscf/day')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="frictionfactor" [title]="getFielTextWithUnit('Friction Factor', 'frictionfactor')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="holdupfactor" [title]="getFielTextWithUnit('Hold Up Factor', 'holdupfactor')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="suctionloss" [title]="getFielTextWithUnit('Suction Loss', 'suctionloss')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="diffuserloss" [title]="getFielTextWithUnit('Diffuser Loss', 'diffuserloss')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="kn" [title]="getFielTextWithUnit('kn', 'kn')" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pi" [title]="getFielTextWithUnit('PI', 'pi', 'STB/day/psi')"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pip" [title]="getFielTextWithUnit('PIP', 'pip', 'psig')"
              [width]="120"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column title="Click To Save" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input kendoRadioButton type="radio" name="dataItem.wellnumber" id="dataItem.casenumber"
                (click)="onRadioClick(dataItem)">
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="JP Calibration.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="JP Calibration.pdf" [allPages]="true">
            <kendo-grid-column field="wellnumber" title="Well  Number"></kendo-grid-column>
            <kendo-grid-column-group title="Calibration Match Parameters">
              <kendo-grid-column field="executedate" title="Executed On Date" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="respressure"
                [title]="getFielTextWithUnit('Res Pressure', 'respressure', 'psig')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="liquidrate"
                [title]="getFielTextWithUnit('Liquid Rate', 'liquidrate', 'STB/day')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="oilrate" [title]="getFielTextWithUnit('Oil Rate', 'oilrate', 'STB/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="waterrate" [title]="getFielTextWithUnit('Water Rate', 'waterrate', 'STB/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="gasrate" [title]="getFielTextWithUnit('Gas Rate', 'gasrate', 'MMscf/day')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="frictionfactor"
                [title]="getFielTextWithUnit('Friction Factor', 'frictionfactor')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="holdupfactor" [title]="getFielTextWithUnit('Hold Up Factor', 'holdupfactor')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="suctionloss" [title]="getFielTextWithUnit('Suction Loss', 'suctionloss')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="diffuserloss" [title]="getFielTextWithUnit('Diffuser Loss', 'diffuserloss')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="kn" [title]="getFielTextWithUnit('kn', 'kn')" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pi" [title]="getFielTextWithUnit('PI', 'pi', 'STB/day/psi')"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pip" [title]="getFielTextWithUnit('PIP', 'pip', 'psig')"
                [width]="120"></kendo-grid-column>
            </kendo-grid-column-group>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
</div>
