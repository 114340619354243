import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { HighchartsChartModule } from 'highcharts-angular';
import { SlbCardGroupModule } from '@slb-dls/angular-material/card-group';
import { SlbDateTimePickerModule } from '@slb-dls/angular-material/date-time-picker';
import { SlbDatePickerModule } from '@slb-dls/angular-material/date-picker';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { SlbModalModule } from '@slb-dls/angular-material/modal';
import { SlbDatePickerRangeModule } from '@slb-dls/angular-material/date-range-picker';
import { SlbSharedModule } from '@slb-dls/angular-material/shared';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { SlbPopoverModule } from '@slb-dls/angular-material/popover';
import { SlbNotificationModule } from '@slb-dls/angular-material/notification';
import { SlbNotificationsPanelModule } from '@slb-dls/angular-material/notifications-panel';
import { SlbNavigationFrameworkModule } from '@slb-dls/angular-material/navigation-framework';
import { SlbBreadcrumbsModule } from '@slb-dls/angular-material/breadcrumbs';
import { SlbLogoutModule } from '@slb-dls/angular-material/logout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchDropdownComponent } from 'src/app/common-component/search-dropdown/search-dropdown.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExportToExcelDirective } from 'src/app/directives/export-to-excel.directive';
import { GridTransformDirective } from 'src/app/directives/grid-transform.directive';
import { PumpchartComponent } from 'src/app/common-component/pump-chart/pump-chart.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GridAutoTooltipDirective } from 'src/app/directives/grid-auto-tooltip.directive';
import { ProductionChartCardComponent } from 'src/app/automated-dashboard/automateddashboard/production-chart-card/production-chart-card.component';
import { AutoHeightDirective } from 'src/app/directives/auto-height.directive';
import { ToggleFullscreenDirective } from 'src/app/directives/toggle-fullscreen.directive';
import { InjectionChartCardComponent } from 'src/app/automated-dashboard/automateddashboard/injection-chart-card/injection-chart-card.component';
import { IntervalSelectorComponent } from 'src/app/common-component/interval-selector/interval-selector.component';
import { DateTimeFilterComponent } from 'src/app/common-component/date-time-filter/date-time-filter.component';

@NgModule({
  declarations: [
    HierarchyComponent,
    SearchDropdownComponent,
    ExportToExcelDirective,
    GridTransformDirective,
    PumpchartComponent,
    GridAutoTooltipDirective,
    ProductionChartCardComponent,
    AutoHeightDirective,
    ToggleFullscreenDirective,
    InjectionChartCardComponent,
    IntervalSelectorComponent,
    DateTimeFilterComponent,


  ],
  imports: [
   CommonModule,
   MatButtonToggleModule,
   MatCardModule,
   MatDialogModule,
   MatCheckboxModule,
   MatMomentDateModule,
   MatInputModule,
   MatFormFieldModule,
   MatButtonModule,
   MatToolbarModule,
   MatListModule,
   MatIconModule,
   MatMenuModule,
   MatDialogModule,
   MatCheckboxModule,
   MatRadioModule,
   MatSlideToggleModule,
   MatTooltipModule,
   MatButtonToggleModule,
   MatSelectModule,
   MatCardModule,
   MatProgressSpinnerModule,

   SlbSharedModule,
   SlbButtonModule,
   SlbFormFieldModule,
   SlbPopoverModule,
   SlbNotificationModule,
   SlbNotificationsPanelModule,
   SlbNavigationFrameworkModule,
   SlbBreadcrumbsModule,
   SlbLogoutModule,
   SlbDatePickerRangeModule,
   SlbModalModule,
   GridModule,
   SlbDatePickerModule,
   SlbDateTimePickerModule,
   SlbCardGroupModule,
   HighchartsChartModule,
   DropDownsModule,
   FormsModule,
   ReactiveFormsModule,
   MatGridListModule,
   PDFViewerModule,
   ExcelExportModule,
   PDFExportModule,
   ExcelModule,
   PDFModule,
   MatDatepickerModule,
   MatIconModule,
   
  ],
  exports : [
    HierarchyComponent,
    SearchDropdownComponent,
    PumpchartComponent,
    MatButtonToggleModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    SlbSharedModule,
    SlbButtonModule,
    SlbFormFieldModule,
    SlbPopoverModule,
    SlbNotificationModule,
    SlbNotificationsPanelModule,
    SlbNavigationFrameworkModule,
    SlbBreadcrumbsModule,
    SlbLogoutModule,
    SlbDatePickerRangeModule,
    SlbModalModule,
    GridModule,
    ExcelModule,
    PDFModule,
    SlbDatePickerModule,
    SlbDateTimePickerModule,
    SlbCardGroupModule,
    HighchartsChartModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    PDFViewerModule,
    ExcelExportModule,
    PDFExportModule,
    ExportToExcelDirective,
    GridTransformDirective,
    GridAutoTooltipDirective,
    MatIconModule,
    ProductionChartCardComponent,
    AutoHeightDirective,
    ToggleFullscreenDirective,
    InjectionChartCardComponent,
    IntervalSelectorComponent,
    DateTimeFilterComponent,

  ],
})
export class ShareModule { }
