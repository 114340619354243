<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [showDateRange]="false" (onFieldChange)="clearWellSelection()"
    (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)"
    (onclear)="clearWellSelection()" [showClearButton]="true" [filterOnWellType]="false"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card class="example-card" autoHeight>
    <mat-card-header>
      <mat-card-title>Well Book Summary</mat-card-title>
      <mat-button-toggle-group class="submenu" (change)="onTabChange($event)"[(value)]="selectedTab">
        <mat-button-toggle [value]="'wellDetails'">Well Details</mat-button-toggle>
        <mat-button-toggle [value]="'wellData'">Well Data</mat-button-toggle>
        <mat-button-toggle [value]="'productionData'">{{ selectedWellType === 'Injector' ? 'Injection Data' : 'Production Data'}}</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card-header>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="!globalLoading && loading" class="spinner__loading loading-content">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div class="no-data" *ngIf="summaryData === null && !loading && !globalLoading">
        <p *ngIf="wellSelected">No data available</p>
        <p *ngIf="!wellSelected">Please select Well</p>
      </div>
      <div *ngIf="wellSelected && !loading && !globalLoading" style="position: relative;">
        <div *ngIf="selectedTab === 'wellDetails'" class="summary-data">
          <div class="box-content" *ngIf="summaryData">
            <div class="box">
              <h1>Well Drilled on</h1>
              <span class="box-value">{{ summaryData.wellDrilledOnDate }}</span>
              <span>{{ summaryData.wellDrilledOnDateRemarks }}</span>
            </div>
            <div class="box">
              <h1>Well Completed on</h1>
              <span class="box-value">{{ summaryData.wellCompletedOnDate }}</span>
              <span>{{ summaryData.wellCompletedOnDateRemarks }}</span>
            </div>
            <div class="box">
              <h1>Start Date of Production</h1>
              <span class="box-value">{{ summaryData.startProductionDate }}</span>
            </div>
            <div class="box">
              <h1>Type of Well</h1>
              <span class="box-value">{{ summaryData.wellType }}</span>
            </div>
            <div class="box">
              <h1>Type of Artificial Lift</h1>
              <span class="box-value">{{ summaryData.liftTypeInitialDate }}</span>
              <span>{{ summaryData.liftType }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="wellSelected && wellBookData && selectedTab !== 'productionData'">
          <app-well-details *ngIf="selectedTab === 'wellDetails'" [wellBookData]="wellBookData"></app-well-details>
          <app-well-data *ngIf="selectedTab === 'wellData'" [masterWellDataSheet]="wellBookData.masterWellDataSheet"></app-well-data>
        </div>
        <div *ngIf="wellSelected && wellBookData && selectedTab === 'productionData'" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0;">
          <app-production-data *ngIf="selectedTab === 'productionData' && selectedWellType !== 'Injector'" [wellSelected]="wellSelected"></app-production-data>
          <app-injection-data *ngIf="selectedTab === 'productionData' && selectedWellType === 'Injector'" [wellSelected]="wellSelected"></app-injection-data>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
