<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [getListofAllWellsUnderField]="true" [timerangeDifference]="7" (onWellchange)="onFilterWellChange($event)" (onclear)="clear()" (ondatechange)="onDateSelected($event)"></app-hierarchy>
  </span>
</div>
<div class="content-left-margin">
  <div class="grid-container">
    <div class="grid-child">
      <mat-card id="divhfchart">
        <app-hfchart [wellid]="selectedWell" [startDate]="date1" [endDate]="date2"></app-hfchart>
      </mat-card>
    </div>
    <div class="grid-child">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>ALS Alarms</mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'"></app-auto-refresh>
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="alarmgridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" [sortable]="true" kendoGridSelectBy="well" [filterable]="true" [height]="340" appGridTransform>
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="alarmgridData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </ng-template>
            <kendo-grid-column field="well" title="Well" [width]="160"></kendo-grid-column>
            <kendo-grid-column field="workflowName" title="Workflow  Name" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="startDateTime_ts" title="Start Date">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="startDateTime">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="endDateTime_ts" title="End Date" [hidden]="true">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="startDateTime">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="alarmName" title="Alarm Name"></kendo-grid-column>
            <kendo-grid-column field="alarmFlags" title="Alarm Flags"></kendo-grid-column>
            <kendo-grid-column field="alarmMessage" title="Alarm Message" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="alarmType" title="Alarm Type" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="alarmLevel" title="Alarm Level"></kendo-grid-column>
            <kendo-grid-excel fileName="ALS Alarms.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="ALS Alarms.pdf" [allPages]="true">
              <kendo-grid-column field="well" title="Well" [width]="160"></kendo-grid-column>
              <kendo-grid-column field="workflowName" title="Workflow  Name" [width]="150"></kendo-grid-column>
              <kendo-grid-column field="startDateTime" title="Start Date"></kendo-grid-column>
              <kendo-grid-column field="alarmName" title="Alarm Name"></kendo-grid-column>
              <kendo-grid-column field="alarmFlags" title="Alarm Flags"></kendo-grid-column>
              <kendo-grid-column field="alarmLevel" title="Alarm Level"></kendo-grid-column>
            </kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div style="margin-top: 10px;">
    <mat-card>
      <app-i-frame title="Parameter Demo" [src]="iframeurl"></app-i-frame>
    </mat-card>
  </div>
</div>

