import moment from 'moment';

export function customFormatDate(value: any, format: string = 'DD-MM-YYYY HH:mm') {
  if (value === null || value === undefined || isNaN(value))
    return '';

  const formatted = moment(value).format(format);
  return formatted !== 'Invalid date' ? formatted : '';
}

export function customFormatDateFromString(value?: string, fromFormat: string = 'YYYY-MM-DD HH:mm', toFormat: string = 'DD-MM-YYYY HH:mm') {
  if (!value)
    return null;

  const formated = moment(value, fromFormat).format(toFormat);
  return formated !== 'Invalid date' ? formated : null;
}
