<div class="content-left-margin float-header-filter">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true"
    [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [timerangeDifference]="'7'"
    [getListofAllWellsUnderField]="true" [filterOnWellType]="false" (onWellchange)="onFilterWellChange($event)"
    (onclear)="clear()" (ondatechange)="onDateSelected($event)"></app-hierarchy>
</div>

<div class="content-left-margin content-right-margin">
  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Summary</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true">
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="well" title="Well Name"></kendo-grid-column>
          <kendo-grid-column field="wellstatus" title="Well Status"></kendo-grid-column>
          <kendo-grid-column field="startdate_ts" title="Start Date">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="startdate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="starttime" title="Time"></kendo-grid-column>
          <kendo-grid-column field="enddate_ts" title="End Date">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.enddate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="enddate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="endtime" title="Time"></kendo-grid-column>
          <kendo-grid-column field="duration" title="Duration(hrs)(Diff between start and end)" [filterable]="false"></kendo-grid-column>
          <kendo-grid-excel fileName="Summary.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Summary.pdf" [allPages]="true"></kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <mat-card class="pumpchart">
      <app-summary-pump-chart [well]="this.selectedWell ?? ''" [showCardTitle]="true"  [granularity]="pumpparametergranuality" [Lifttype]="this.Lifttype ?? ''" [Welltype]="this.Welltype ?? ''" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate"></app-summary-pump-chart>
    </mat-card>
  </div>
</div>
