<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [filterOnWellType]="false" (onFieldChange)="clearWellSelection()" (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)" (onclear)="clearWellSelection()" [showClearButton]="true"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card class="example-card" id="card" autoHeight>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="loading" class="spinner__loading loading-container">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div style="min-height: auto; margin-bottom: 10px;">
        <kendo-grid #wellTagsGrid [kendoGridBinding]="wellTags" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [rowClass]="rowWellTagsClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 2 }">
          <ng-template kendoGridToolbarTemplate>
            <div class="row">
              <div class="col-sm-10">
                <h4>Well Tags</h4>
              </div>
              <div class="col-sm-2">
                <div style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="wellTagsGrid" [exportColumnRules]="exportColumnRules" [data]="wellTags">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('wellTags')">
                    <mat-icon *ngIf="displayWellTagsGrid" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayWellTagsGrid" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <kendo-grid-column field="tagName" title="Tag Name"></kendo-grid-column>
          <kendo-grid-column field="unit" title="Unit" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="description" title="Description"></kendo-grid-column>
          <kendo-grid-column field="createdDate_ts" title="Created Date" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.createdDate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="createdDate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="lastUpdatedOn_ts" title="Last Updated On" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.lastUpdatedOn }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="lastUpdatedOn">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Well Tags.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Well Tags.pdf" [allPages]="true"></kendo-grid-pdf>
        </kendo-grid>
      </div>
      <div style="min-height: auto;">
        <kendo-grid #equipmentTagsGrid [kendoGridBinding]="equipmentTags" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [rowClass]="rowEquipmentTagsClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 2 }">
          <ng-template kendoGridToolbarTemplate>
            <div class="row">
              <div class="col-sm-10">
                <h4 style="display: inline-block; width: auto; margin-right: 10px;">Equipment Tags</h4>
                <mat-form-field slbFormField class="form-field" appearance="outline" style="padding: 0; height: 30px;">
                  <mat-select placeholder="Lift Type" #input [panelClass]="'dls-panel'" [(ngModel)]="liftTypeSelected" (selectionChange)="onLiftTypeChange()" [hideSingleSelectionIndicator]="true" [disabled]="equipmentSelectionTags.length <= 1">
                    <mat-option [value]="'all'">All</mat-option>
                    <mat-option *ngFor="let liftType of equipmentSelectionTags" [value]="liftType.equipment" [disabled]="!liftType.enabled">{{ liftType.equipment.toUpperCase() }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2">
                <div style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="equipmentTagsGrid" [exportColumnRules]="exportColumnRules" [data]="equipmentTags">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('equipmentTags')">
                    <mat-icon *ngIf="displayEquipmentTagsGrid" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayEquipmentTagsGrid" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <kendo-grid-column field="tagName" title="Tag Name"></kendo-grid-column>
          <kendo-grid-column field="unit" title="Unit" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="description" title="Description"></kendo-grid-column>
          <kendo-grid-column field="createdDate_ts" title="Created Date" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.createdDate }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="createdDate">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="lastUpdatedOn_ts" title="Last Updated On" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.lastUpdatedOn }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="lastUpdatedOn">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Equipment Tags.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Equipment Tags.pdf" [allPages]="true"></kendo-grid-pdf>
        </kendo-grid>
      </div>
    </mat-card-content>
  </mat-card>
</div>
