<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [showDateRange]="false" [filterOnWellType]="false" (onFieldChange)="clearWellFiles()" (onWellPadChange)="clearWellFiles()" (onWellchange)="handleWellChange($event)" (onclear)="clearWellFiles()" [showClearButton]="true"></app-hierarchy>
  </span>
  <mat-form-field class="form-field" id="file-type-selection" appearance="outline" #field>
    <mat-select placeholder="Select file type" #input [panelClass]="'dls-panel'" (selectionChange)="selectFileType()" [(value)]="selectedFileType">
      <mat-option value="all">All Files</mat-option>
      <mat-option *ngFor="let fileData of filesData" [disabled]="fileData.files.length <= 0" [value]="fileData.fileType">{{ fileData.label }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="page-content">
  <mat-card id="card" class="example-card" autoHeight>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="loading" class="spinner__loading loading-container">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div class="viewer-block">
        <div>
          <kendo-grid [kendoGridBinding]="files" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="{ checkboxOnly: true }" kendoGridSelectBy="id" [(selectedKeys)]="selectedRows" [filterable]="true" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card' }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <div class="col-sm-10">
                  <h4>Survey Data</h4>
                </div>
                <div class="col-sm-2">
                  <div style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                    <button *ngIf="selectedRows.length > 0" type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="downloadAllFiles()">
                      <span class="k-button-text">Download</span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
            <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" title="ID" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="category" title="File Category"></kendo-grid-column>
            <kendo-grid-column field="name" title="File Name"></kendo-grid-column>
            <kendo-grid-column field="date" title="Date Uploaded" [width]="160"></kendo-grid-column>
            <kendo-grid-column field="action" title="Action" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                <a slb-icon-button routerLink="." title="Download" (click)="downloadFile(dataItem)">
                  <mat-icon style="color: #6BA7FF;" svgIcon="download"></mat-icon>
                </a>
                <a slb-icon-button routerLink="." title="Preview" (click)="previewFile(dataItem)" *ngIf="dataItem.type === 'PDF' || dataItem.type === 'Image'">
                  <mat-icon style="color: #6BA7FF;" svgIcon="preview"></mat-icon>
                </a>
                <a slb-icon-button routerLink="." title="Preview" (click)="previewLasFile(dataItem)" *ngIf="dataItem.extension === 'las'">
                  <mat-icon style="color: #6BA7FF;" svgIcon="preview"></mat-icon>
                </a>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
        <div *ngIf="!pdfUrl && !image && !lasData" class="no-preview">
          <p>No file to view</p>
        </div>
        <div *ngIf="pdfUrl !== undefined" class="pdf-viewer" #pdfContainer>
          <button type="button" class="pdf-fullscreen k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [toggleFullscreen]="pdfContainer" (onFullscreenChanged)="togglePdfViewerSize($event)">
            <mat-icon *ngIf="!showPdfFullScreen" svgIcon="expand" aria-hidden="false" aria-label="Expand" title="View full screen"></mat-icon>
            <mat-icon *ngIf="showPdfFullScreen" svgIcon="close" aria-hidden="false" aria-label="Close" title="Close full screen"></mat-icon>
          </button>
          <kendo-pdfviewer #pdfViewer [url]="pdfUrl" autoFitPdf></kendo-pdfviewer>
        </div>
        <div *ngIf="image !== null" class="image-viewer">
          <app-image-viewer [imageUrl]="image.url" [enableAnnotations]="true"  [imageName]="image.name"></app-image-viewer>
        </div>
        <div *ngIf="lasData !== null" class="las-viewer">
          <app-las-viewer [lasData]="lasData"></app-las-viewer>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
