import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
})
export class ImageViewerComponent {

  @Input() imageUrl: string | undefined;
  @Input() showFullScreenButton: boolean = true;
  @Input() enableAnnotations: boolean = false;
  @Input() imageName : any ;
  @ViewChild('image') image: ElementRef<HTMLElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  public scaleFactor: number = 1;
  public isDragging: boolean = false;
  public imageLoaded: boolean = false;
  public mode: 'DRAW' | 'TEXT' | 'DRAG' = 'DRAG';
  public backHistory: ImageData[] = [];
  public frontHistory: ImageData[] = [];
  public isFullScreen: boolean = false;
  public selectedColor: string = '#000000';

  private startX: number = 0;
  private startY: number = 0;
  private translateX: number = 0;
  private translateY: number = 0;
  private isDrawing: boolean = false;
  private ctx: CanvasRenderingContext2D;

  constructor() {

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setCanvasSize();
  }

  public onColorChange(event: any) {
    this.selectedColor = event.target.value ?? '#000000';
    this.ctx.strokeStyle = this.selectedColor;
    this.ctx.fillStyle = this.selectedColor;
  }

  public zoomIn() {
    if (this.scaleFactor < 2) {
      this.scaleFactor += 0.1;
      this.updateCanvasTransform();
    }
  }

  public zoomOut() {
    if (this.scaleFactor >= 0.2) {
      this.scaleFactor -= 0.1;
      this.updateCanvasTransform();
    }
  }

  public toggleFullscreen(isFullScreen: boolean) {
    this.isFullScreen = isFullScreen;

    if (this.enableAnnotations)
      this.setCanvasSize();
  }

  public resetZoom() {
    this.scaleFactor = 1;
    this.translateX = 0;
    this.translateY = 0;

    this.updateCanvasTransform();
  }

  public onImageLoaded() {
    this.imageLoaded = true;

    this.ctx = this.canvas?.nativeElement.getContext('2d')!;
    this.setCanvasSize();
  }

  public undo() {
    if (this.backHistory.length > 0) {
      const lastState = this.backHistory.pop();

      if (lastState) {
        const currentState = this.ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.frontHistory = [currentState, ...this.frontHistory];

        this.ctx.putImageData(lastState, 0, 0);
      }
    }
  }

  public redo() {
    if (this.frontHistory.length > 0) {
      const oldState = this.frontHistory.shift();

      if (oldState) {
        this.saveState(false);
        this.ctx.putImageData(oldState, 0, 0);
      }
    }
  }

  private saveState(deleteFrontHistory = true) {
    if (this.ctx) {
      if (deleteFrontHistory)
        this.frontHistory = [];

      const currentState = this.ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      this.backHistory.push(currentState);
    }
  }

  private setCanvasDrawed(annotations: any) {
    if (annotations) {
      const image = new Image();
      image.onload = () => {
        this.ctx.drawImage(image, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      };

      image.src = annotations;
    }
  }

  public clearCanvas() {
    this.ctx?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.backHistory = [];
    this.frontHistory = [];
  }

  public onMouseDown(event: MouseEvent) {
    if (this.mode === 'DRAW') {
      this.startDrawing(event);
    } else if (this.mode === 'TEXT') {
      this.addText(event);
    } else if (this.mode === 'DRAG') {
      this.startDrag(event);
    }
  }

  public onMouseMove(event: MouseEvent) {
    if (this.mode === 'DRAW')
      this.draw(event);
  }

  public onMouseUpOrLeave() {
    if (this.mode === 'DRAW')
      this.stopDrawing();
  }

  private addText(event: MouseEvent) {
    this.saveState();
    this.removeTextInput();

    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const input = document.createElement('input');
    input.id = 'canvasInputText';
    input.type = 'text';
    input.style.position = 'absolute';
    input.style.left = `${event.clientX}px`;
    input.style.top = `${event.clientY - 15}px`;
    input.style.border = '1px dashed black';
    input.style.font = '12px SLBSans, Roboto, "Helvetica Neue", sans-serif';
    input.style.color = this.selectedColor;
    input.style.padding = '2px';
    input.style.zIndex = '1000';

    document.body.appendChild(input);

    setTimeout(() => {
      input.focus();

      const setText = () => {
        this.ctx.font = '12px SLBSans, Roboto, "Helvetica Neue", sans-serif';
        this.ctx.fillStyle = this.selectedColor;
        this.ctx.fillText(input.value, x, y);
        this.removeTextInput();
      }

      input.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Enter')
          setText();
      });

      input.addEventListener('blur', () => setText());
    }, 250);
  }

  private removeTextInput() {
    try {
      const existingInput = document.getElementById('canvasInputText');

      if (existingInput)
        document.body.removeChild(existingInput);
    } catch {

    }

  }

  private startDrawing(event: MouseEvent) {
    this.saveState();
    this.isDrawing = true;
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.selectedColor;

    const rect = this.canvas.nativeElement.getBoundingClientRect();
    this.ctx.moveTo(event.clientX - rect.left, event.clientY - rect.top);
  }

  private draw(event: MouseEvent) {
    if (!this.isDrawing)
      return;

    const rect = this.canvas.nativeElement.getBoundingClientRect();
    this.ctx.lineTo(event.clientX - rect.left, event.clientY - rect.top);
    this.ctx.stroke();
  }

  private stopDrawing() {
    this.isDrawing = false;
    this.ctx.closePath();
  }

  private startDrag(event: MouseEvent) {
    event.preventDefault();

    this.isDragging = true;
    this.startX = event.clientX;
    this.startY = event.clientY;

    document.addEventListener('mousemove', this.drag.bind(this));
    document.addEventListener('mouseup', () => this.stopDrag());
  }

  private drag(event: MouseEvent) {
    if (this.isDragging) {
      this.translateX += event.clientX - this.startX;
      this.translateY += event.clientY - this.startY;

      this.startX = event.clientX;
      this.startY = event.clientY;

      this.updateCanvasTransform();
    }
  }

  private stopDrag() {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.drag.bind(this));
  }

  private setCanvasSize() {
    const annotations = this.canvas?.nativeElement?.toDataURL('image/png');

    const canvas = this.canvas?.nativeElement;
    const image = this.image?.nativeElement;
    const container = image.parentElement;

    if (!this.imageLoaded || !canvas || !image || !container)
      return;

    const topOffset = (container.clientHeight - image.clientHeight) / 2;
    const leftOffset = (container.clientWidth - image.clientWidth) / 2;

    canvas.width = image.clientWidth;
    canvas.height = image.clientHeight;
    canvas.style.top = `${topOffset}px`;
    canvas.style.left = `${leftOffset}px`;

    this.updateCanvasTransform();
    this.setCanvasDrawed(annotations);
  }

  private updateCanvasTransform() {
    if (this.image?.nativeElement?.style)
      this.image.nativeElement.style.transform = `scale(${this.scaleFactor}) translate(${this.translateX}px, ${this.translateY}px)`;

    if (this.canvas?.nativeElement?.style)
      this.canvas.nativeElement.style.transform = `scale(${this.scaleFactor}) translate(${this.translateX}px, ${this.translateY}px)`;
  }
  public downloadAnnotatedImage() {
    if (!this.canvas || !this.image) return;
  
    // Create an offscreen canvas
    const combinedCanvas = document.createElement('canvas');
    const imageElement = this.image.nativeElement as HTMLImageElement;
    const annotationCanvas = this.canvas.nativeElement;
  
    // Set the size of the combined canvas
    combinedCanvas.width = imageElement.width;
    combinedCanvas.height = imageElement.height;
  
    const ctx = combinedCanvas.getContext('2d');
    if (!ctx) return;
  
    // Draw the base image onto the combined canvas
    ctx.drawImage(imageElement, 0, 0, combinedCanvas.width, combinedCanvas.height);
  
    // Overlay the annotation canvas
    ctx.drawImage(annotationCanvas, 0, 0, combinedCanvas.width, combinedCanvas.height);
  
    // Convert the combined canvas to a data URL
    const dataURL = combinedCanvas.toDataURL('image/png');
  
    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = this.imageName != null && this.imageName !=undefined && this.imageName !="" ? this.imageName : "image.png"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  }
