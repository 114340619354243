

//To add new condition add value in respective Condition array like for ESP add in <ESPCondition>
// To new Parameter add parameter in respective parameterType(ESPparameterType) array and in parametertype refrence array (ESPparameterTypeofCondition)
export const ESPwearparameterType: any[] = [
    { "name": "Drop", "value": "drop" },
    { "name": "Value", "value": "dropvalue" },
]

export const ESPparameterType: any[] = [
    { "name": "THP-FAP", "value": "diffThpFap" },
    { "name": "THP", "value": "Thp" },
    { "name": "Current", "value": "Current" },
    { "name": "PDP", "value": "Pdp" },
    { "name": "THT", "value": "Tht" },
    { "name": "PIP", "value": "Pip" },
    { "name": "Motor Temperature", "value": "MotorTemp" },
    { "name": "Frequency", "value": "Frequency" },
    { "name": "PDP-PIP", "value": "diffPdpPip" },
    { "name": "Torque", "value": "torque" },
    { "name": "Vibration", "value": "Vibration" },
    { "name":"PMM Frequency","value":"frequencyPmm"},
    { "name":"Current(Max-Min)","value":"diffCurrentMaxMin"},
    { "name":"Frequency(Max-Min)","value":"diffFrequencyMaxMin"},
    // Trip comparison Parameter
    { "name": "MWT Delta", "value": "mwtDelta" },
    { "name": "Current % Low Limit", "value": "curLowLmt" },
    { "name": "Current % High Limit", "value": "curHighLmt" },
    { "name": "PIP % Low Limit", "value": "curPipLmt" },
    { "name": "Drop", "value": "drop" },
    { "name": "Value", "value": "dropvalue" }

]

export const PCPparameterType: any[] = [
    { "name": "Pump Frequency", "value": "Frequency" },
    { "name": "Frequency T", "value": "Frequencyt" },
    { "name": "Frequency (T-2)", "value": "Frequencyt2" },
    //{ "name": "Pump Frequency(Online Check)", "value": "FrequencyOnline" },
    { "name": "Voltage", "value": "voltage" },
    { "name": "Current", "value": "Current" },
    { "name": "PIP", "value": "Pip" },
    { "name": "PDP", "value": "Pdp" },
    { "name": "THP-FAP", "value": "diffFapThp" },
    { "name": "Motor Temperature", "value": "MotorTemp" },
    { "name": "THP", "value": "Thp" },
    { "name": "THT", "value": "Tht" },
    { "name": "Torque", "value": "torque" },
    { "name": "PDP-PIP", "value": "diffPdpPip" },
    { "name": "Flow DP Low Limit", "value": "flowDpLowLmt" },
    { "name": "Flow DP High Limit", "value": "flowDpHighLmt" },
    { "name": "Torque % Low Limit", "value": "torqueLowLmt" },
    { "name": "Torque % High Limit", "value": "torqueHighLmt" },
    { "name": "Current % Low Limit", "value": "curLowLmt" },
    { "name": "Current % High Limit", "value": "curHighLmt" },
    { "name": "PIP % Low Limit", "value": "curPipLmt" },
    { "name": "Torque Post Well Trip", "value": "TorquePostTrip" },
    { "name": "Frequency Post Well Trip", "value": "FrequencyPostTrip" },
    { "name": "RPM", "value": "rpm" },

   
]
export const JPparameterType: any[] = [
    { "name": "PF Rate", "value": "pfRate" },
    { "name": "PF Rate High", "value": "pfRatehigh" },
    { "name": "PF Rate Low", "value": "pfRatelow" },
    { "name": "A Casing Pressure Point", "value": "acasingpresspoint" },
    { "name": "THT", "value": "Tht" },
    { "name": "A Casing Pressure", "value": "aCasingPress" },
    // { "name":"Current","value":"Current"},
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnuls" }, //diffPfHeaderPressAAnnulsa
    { "name": "THP", "value": "Thp" },
    { "name": "THP-FAP", "value": "diffThpFap" },
]

export const ESPCondition: any[] = [
    { "name": "Shaft Broken", "value": "ShaftBroken" },
    // { "name":"DHG Failure","value":"DhgFailure"},
    { "name": "ESP Trip", "value": "EspTrip" },
    { "name": "High MWT", "value": "HighMWT" },
    { "name": "ESP Vibration", "value": "ESPVibration" },
    { "name": "PI Drop", "value": "piCalibratedProductivityIndexDrop" },
    { "name": "ESP Wear", "value": "PumpWearFactor" },
    { "name": "Trip threshold comparison", "value": "TripComparison" },
    { "name": "Gas Interference", "value": "GasInterference" },
    
]

export const ESPparameterTypeofCondition: any[] = [
    //shaft broken
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "ShaftBroken" },
    { "name": "THP", "value": "Thp", "condition": "ShaftBroken" },
    { "name": "Current", "value": "Current", "condition": "ShaftBroken" },
    { "name": "PDP", "value": "Pdp", "condition": "ShaftBroken" },
    { "name": "THT", "value": "Tht", "condition": "ShaftBroken" },
    { "name": "PIP", "value": "Pip", "condition": "ShaftBroken" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "ShaftBroken" },
    { "name": "Frequency", "value": "Frequency", "condition": "ShaftBroken" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "ShaftBroken" },
    { "name": "PMM Frequency", "value": "frequencyPmm", "condition": "ShaftBroken" },

    //{ "name":"Torque","value":"torque", "condition":"ShaftBroken"},

    //Esp trip
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "EspTrip" },
    { "name": "THP", "value": "Thp", "condition": "EspTrip" },
    { "name": "Current", "value": "Current", "condition": "EspTrip" },
    { "name": "PDP", "value": "Pdp", "condition": "EspTrip" },
    { "name": "THT", "value": "Tht", "condition": "EspTrip" },
    { "name": "PIP", "value": "Pip", "condition": "EspTrip" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "EspTrip" },
    { "name": "Frequency", "value": "Frequency", "condition": "EspTrip" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "EspTrip" },
    { "name": "PMM Frequency", "value": "frequencyPmm", "condition": "EspTrip" },

    //{ "name":"Torque","value":"torque", "condition":"EspTrip"},

    //HighMWT
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "HighMWT" },

    //ESPVibration
    { "name": "Vibration", "value": "Vibration", "condition": "ESPVibration" },

    //"value":"PumpWearFactor"
    { "name": "Drop", "value": "drop", "condition": "PumpWearFactor" },
    { "name": "Value", "value": "dropvalue", "condition": "PumpWearFactor" },


    // Trip comparison Parameter
    { "name": "MWT Delta", "value": "mwtDelta", "condition": "TripComparison" },
    { "name": "Current % Low Limit", "value": "curLowLmt", "condition": "TripComparison" },
    { "name": "Current % High Limit", "value": "curHighLmt", "condition": "TripComparison" },
    { "name": "PIP % Low Limit", "value": "curPipLmt", "condition": "TripComparison" },

    //Gas Interference
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "GasInterference" },
    { "name": "THP", "value": "Thp", "condition": "GasInterference" },
    { "name": "Current", "value": "Current", "condition": "GasInterference" },
    { "name": "PDP", "value": "Pdp", "condition": "GasInterference" },
    { "name": "THT", "value": "Tht", "condition": "GasInterference" },
    { "name": "PIP", "value": "Pip", "condition": "GasInterference" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "GasInterference" },
    { "name": "Frequency", "value": "Frequency", "condition": "GasInterference" },
    { "name": "PMM Frequency", "value": "frequencyPmm", "condition": "GasInterference" },
    { "name":"Current(Max-Min)","value":"diffCurrentMaxMin", "condition": "GasInterference"},
    { "name":"Frequency(Max-Min)","value":"diffFrequencyMaxMin", "condition": "GasInterference"},

]
export const PCPCondition: any[] = [
    { "name": "Rod Failure", "value": "RodFailure" },
    { "name": "Rod Failure (Post Well Trip)", "value": "RodFailurePostTrip" },
    { "name": "Well Trip", "value": "WellTrip" },
    { "name": "Tubing Failure", "value": "TubingFailure" },
    { "name": "Trip threshold comparison", "value": "TripComparison" },
    { "name": "PCP Stator Swelling (RPM_Decreased)", "value": "StatorSwellingDec" },
    { "name": "PCP Stator Swelling (RPM_No Change)", "value": "StatorSwellingNoChange" }
]

export const PCPparameterTypewithCondition: any[] = [
    //RodFailure
    { "name": "Frequency", "value": "Frequency", "condition": "RodFailure" },
    { "name": "Voltage", "value": "voltage", "condition": "RodFailure" },
    { "name": "Current", "value": "Current", "condition": "RodFailure" },
    { "name": "PIP", "value": "Pip", "condition": "RodFailure" },
    { "name": "PDP", "value": "Pdp", "condition": "RodFailure" },
    { "name": "THP-FAP", "value": "diffFapThp", "condition": "RodFailure" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "RodFailure" },
    { "name": "THP", "value": "Thp", "condition": "RodFailure" },
    { "name": "THT", "value": "Tht", "condition": "RodFailure" },
    { "name": "Torque", "value": "torque", "condition": "RodFailure" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "RodFailure" },
    // { "name": "Torque Post Well Trip", "value": "TorquePostTrip","condition": "RodFailure"  },
    // { "name": "Frequency Post Well Trip", "value": "FrequencyPostTrip","condition": "RodFailure"  },


    //Rod Failure (Post Well Trip)
    { "name": "Frequency", "value": "FrequencyPostTrip", "condition": "RodFailurePostTrip" },
    { "name": "Torque", "value": "TorquePostTrip", "condition": "RodFailurePostTrip" },


    //WellTrip
    { "name": "Frequency", "value": "Frequency", "condition": "WellTrip" },
    { "name": "Frequency T", "value": "Frequencyt", "condition": "WellTrip" },
    { "name": "Frequency (T-2)", "value": "Frequencyt2" , "condition": "WellTrip"},
    { "name": "Voltage", "value": "voltage", "condition": "WellTrip" },
    { "name": "Current", "value": "Current", "condition": "WellTrip" },
    { "name": "PIP", "value": "Pip", "condition": "WellTrip" },
    { "name": "PDP", "value": "Pdp", "condition": "WellTrip" },
    { "name": "THP-FAP", "value": "diffFapThp", "condition": "WellTrip" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "WellTrip" },
    { "name": "THP", "value": "Thp", "condition": "WellTrip" },
    { "name": "THT", "value": "Tht", "condition": "WellTrip" },
    { "name": "Torque", "value": "torque", "condition": "WellTrip" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "WellTrip" },
   

    //TubingFailure
    { "name": "Pump Frequency", "value": "Frequency", "condition": "TubingFailure" },
    { "name": "Voltage", "value": "voltage", "condition": "TubingFailure" },
    { "name": "Current", "value": "Current", "condition": "TubingFailure" },
    { "name": "PIP", "value": "Pip", "condition": "TubingFailure" },
    { "name": "PDP", "value": "Pdp", "condition": "TubingFailure" },
    { "name": "THP-FAP", "value": "diffFapThp", "condition": "TubingFailure" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "TubingFailure" },
    { "name": "THP", "value": "Thp", "condition": "TubingFailure" },
    { "name": "THT", "value": "Tht", "condition": "TubingFailure" },
    { "name": "Torque", "value": "torque", "condition": "TubingFailure" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "TubingFailure" },
    { "name": "Flow DP Low Limit", "value": "flowDpLowLmt","condition": "TripComparison" },
    { "name": "Flow DP High Limit", "value": ":flowDpHighLmt","condition": "TripComparison" },
    { "name": "Torque % Low Limit", "value": "torqueLowLmt","condition": "TripComparison" },
    { "name": "Torque % High Limit", "value": "torqueHighLmt","condition": "TripComparison" },
    { "name": "Current % Low Limit", "value": "curLowLmt" ,"condition": "TripComparison" },
    { "name": "Current % High Limit", "value": "curHighLmt" ,"condition": "TripComparison" },
    { "name": "PIP % Low Limit", "value": "curPipLmt" ,"condition": "TripComparison" },
    //PCP Stator Swelling (RPM_Decreased)
    { "name": "Frequency", "value": "Frequency", "condition": "StatorSwellingDec" },
    { "name": "Voltage", "value": "voltage", "condition": "StatorSwellingDec" },
    { "name": "Current", "value": "Current", "condition": "StatorSwellingDec" },
    { "name": "PIP", "value": "Pip", "condition": "StatorSwellingDec" },
    { "name": "PDP", "value": "Pdp", "condition": "StatorSwellingDec" },
    { "name": "THP-FAP", "value": "diffthpfap", "condition": "StatorSwellingDec" },
    { "name": "Motor Temperature", "value": "MotorTemp", "condition": "StatorSwellingDec" },
    { "name": "THP", "value": "Thp", "condition": "StatorSwellingDec" },
    { "name": "THT", "value": "Tht", "condition": "StatorSwellingDec" },
    { "name": "Torque", "value": "torque", "condition": "StatorSwellingDec" },
    { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "StatorSwellingDec" },
    { "name": "RPM", "value": "rpm", "condition": "StatorSwellingDec" },
     //StatorSwellingNoChange
     { "name": "Frequency", "value": "Frequency", "condition": "StatorSwellingNoChange" },
     { "name": "Voltage", "value": "voltage", "condition": "StatorSwellingNoChange" },
     { "name": "Current", "value": "Current", "condition": "StatorSwellingNoChange" },
     { "name": "PIP", "value": "Pip", "condition": "StatorSwellingNoChange" },
     { "name": "PDP", "value": "Pdp", "condition": "StatorSwellingNoChange" },
     { "name": "THP-FAP", "value": "diffthpfap", "condition": "StatorSwellingNoChange" },
     { "name": "Motor Temperature", "value": "MotorTemp", "condition": "StatorSwellingNoChange" },
     { "name": "THP", "value": "Thp", "condition": "StatorSwellingNoChange" },
     { "name": "THT", "value": "Tht", "condition": "StatorSwellingNoChange" },
     { "name": "Torque", "value": "torque", "condition": "StatorSwellingNoChange" },
     { "name": "PDP-PIP", "value": "diffPdpPip", "condition": "StatorSwellingNoChange" },
     { "name": "RPM", "value": "rpm", "condition": "StatorSwellingNoChange" },

]
export const JPCondition: any[] = [
    { "name": "JP Choking", "value": "PumpChoking" },
    { "name": "Nozzle Broken", "value": "NozzleBroken" },
    { "name": "JP Casing/Packer Leak or Nozzle Broken(Less Probable)", "value": "JpUnset" },
    { "name": "PF Bull Head", "value": "PFBullHead" },
    { "name": "PI Drop", "value": "piCalibratedProductivityIndexDrop" },
    { "name": "JP Casing/Packer /Tubing Leak", "value": "TubingLeak" },
    { "name": "Annulus Dechoking", "value": "AnnulusDechoking" },
    
]
export const JPparameterTypewithCondition: any[] = [
    //PumpChoking
    { "name": "PF Rate", "value": "pfRate", "condition": "PumpChoking" },
    { "name": "THT", "value": "Tht", "condition": "PumpChoking" },
    { "name": "A Casing Pressure", "value": "aCasingPress", "condition": "PumpChoking" },
    // { "name":"Current","value":"Current","condition":"PumpChoking"},
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnnuls", "condition": "PumpChoking" }, //diffPfHeaderPressAAnnulsa
    { "name": "THP", "value": "Thp", "condition": "PumpChoking" },
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "PumpChoking" },

    //NozzleBroken
    { "name": "PF Rate", "value": "pfRate", "condition": "NozzleBroken" },
    { "name": "THT", "value": "Tht", "condition": "NozzleBroken" },
    { "name": "THP", "value": "Thp", "condition": "NozzleBroken" },
    { "name": "A Casing Pressure", "value": "aCasingPress", "condition": "NozzleBroken" },
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnnuls", "condition": "NozzleBroken" }, 
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "NozzleBroken" },

    //JpUnset
    { "name": "PF Rate", "value": "pfRate", "condition": "JpUnset" },
    { "name": "THT", "value": "Tht", "condition": "JpUnset" },
    { "name": "A Casing Pressure", "value": "aCasingPress", "condition": "JpUnset" },
    // { "name":"Current","value":"Current","condition":"JpUnset"},
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnnuls", "condition": "JpUnset" }, //diffPfHeaderPressAAnnulsa
    { "name": "THP", "value": "Thp", "condition": "JpUnset" },
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "JpUnset" },

    { "name": "PF Rate High", "value": "pfRatehigh","condition": "JpUnset" },
    { "name": "PF Rate Low", "value": "pfRatelow","condition": "JpUnset" },
    { "name": "A Casing Pressure Point", "value": "acasingpresspoint","condition": "JpUnset" },

    //PFBullHead
    { "name": "PF Rate", "value": "pfRate", "condition": "PFBullHead" },

    //TubingLeak
    { "name": "PF Rate", "value": "pfRate", "condition": "TubingLeak" },
    { "name": "THT", "value": "Tht", "condition": "TubingLeak" },
    { "name": "A Casing Pressure", "value": "aCasingPress", "condition": "TubingLeak" },
    // { "name":"Current","value":"Current","condition":"PumpChoking"},
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnuls", "condition": "TubingLeak" }, //diffPfHeaderPressAAnnulsa
    { "name": "THP", "value": "Thp", "condition": "TubingLeak" },
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "TubingLeak" },

    //AnnulusDechoking
    { "name": "PF Rate", "value": "pfRate", "condition": "AnnulusDechoking" },
    { "name": "THT", "value": "Tht", "condition": "AnnulusDechoking" },
    { "name": "A Casing Pressure", "value": "aCasingPress", "condition": "AnnulusDechoking" },
    // { "name":"Current","value":"Current","condition":"PumpChoking"},
    { "name": "Pf - HeaderPress", "value": "diffPfHeaderPressAAnnuls", "condition": "AnnulusDechoking" }, //diffPfHeaderPressAAnnulsa
    { "name": "THP", "value": "Thp", "condition": "AnnulusDechoking" },
    { "name": "THP-FAP", "value": "diffThpFap", "condition": "AnnulusDechoking" },
]

export const operationType: any[] = [
    { "value": "Inc", "name": "Increase greater than", "type": "%" },
    { "value": "Dec", "name": "Decrease greater than", "type": "%" },
    { "value": "Dec:lt", "name": "Decrease less than", "type": "%" },
    { "value": "Change:lt", "name": "Change less than", "type": "%" },
    { "value": "Change:gt", "name": "Change greater than", "type": "%" },
    { "value": "GREATERTHAN", "name": "% Change greater than", "type": "%" },
    { "value": "LESSTHAN", "name": "% Change less than", "type": "%" },
    { "value": "GREATERTHANEQUALTO", "name": "% Change greater than equal to", "type": "%" },
    { "value": "LESSTHANEQUALTO", "name": "% Change less than equal to", "type": "%" },
    { "value": "DIFFGREATERTHAN", "name": "Difference greater than", "type": "%" },
    
    { "value": "LESSTHAN", "name": "Value less than", "type": "value" },
    { "value": "GREATERTHAN", "name": "Value greater than", "type": "value" },
    { "value": "GREATERTHANEQUALTO", "name": "Value greater than equal to", "type": "value" },
    { "value": "LESSTHANEQUALTO", "name": "Value less than equal to", "type": "value" },
    { "value": "DIFFGREATERTHAN", "name": "Difference greater than", "type": "value" },

]
export const TolType:any[] = [
    { "value": "tol", "name": "%", "show": "true" },
    { "value": "value", "name": "Value", "show": "true" },
    { "value": "onlinevalue", "name": "Online", "show": "true" },
    { "value": "gradualtol", "name": "Gradual", "show": "true" },
]
